import React, { useEffect } from "react";
import ReactDOM, { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./i18n";
import JQueryScript from "./JQueryScript";
import Jquaryfixedheader from "./Jquaryfixedheader";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
import Notification from "./Common/notification";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from "./components/ErrorBoundaryFallback";

const Root = (
  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
    <BrowserRouter basename="/">
      <Provider store={store}>
        <JQueryScript />
        <Jquaryfixedheader />
        <Notification />
        <App />
      </Provider>
    </BrowserRouter>
  </ErrorBoundary>
);
createRoot(document.getElementById("root")).render(Root);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// "homepage": "http://3.7.8.99/templify-dev",
// "homepage": "https://craetivo.com/",

