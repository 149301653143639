import React, { useState } from "react";
import Innerheader from "../../components/Innerheader";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import checkValidation from "../../validation/ValidationSchema";
import Sidebar from "../../components/Sidebar";
import { changePassword } from "../../api/apiHandler";
import { showPromiseNotification } from "../../Common/notification";

const Change_password = () => {
  const navigate = useNavigate();
  const [isoldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isconfirmPasswordVisible, setconfirmPasswordVisible] = useState(false);
  const { t } = useTranslation();
  const schema = checkValidation([
    "Oldpassword",
    "NewPassword",
    "Confirm_Password2",
  ]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  console.log('errors: ', errors);

  const onSubmit = (data) => {
    const payload = {
      old_password: data?.Oldpassword,
      new_password: data?.NewPassword,
    };

    const myPromise = new Promise((resolve, reject) =>
      changePassword(payload).then((response) => {
        if (response.code === 200) {
          resolve(response);
          navigate("/home");
        } else {
          reject(response);
        }
      })
    );
    showPromiseNotification(myPromise);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const toggleconfirmPasswordVisibility = () => {
    setconfirmPasswordVisible((prevState) => !prevState);
  };

  const toggleoldPasswordVisibility = () => {
    setIsOldPasswordVisible((prevState) => !prevState);
  };

  return (
    <>
      <main>
        <Innerheader />
        <div className="wrapper">
          <Sidebar />
          <div className="content flex-grow-1">
            <section className="category_main_title">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 mb-4 ">
                    <div className="main_gray">
                      <div className="row">
                        <div className="col-12">
                          <div className="login_header">
                            <a className="cursor-pointer" onClick={() => navigate(-1)}>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/left_arrow.svg"
                                }
                              />{" "}
                              {t("back")}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="row align-items-center justify-content-evenly">
                        <div className="col-lg-3 col-md-3 col-sm-6 col-12 mb-3 ">
                          <div className="">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/security.svg"
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
                          <div className="title-text">
                            <h5 className="mb-2 fw-bold text-center">
                              {t("changePassword")}
                            </h5>
                            <p className="mx-auto text-center">
                              {t("passwordNote")}
                            </p>
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="login_form">
                                <div className="row">
                                  <div className="col-12 mb-3">
                                    <div className="form-group left_fixed">
                                      <input
                                        type={
                                          isoldPasswordVisible
                                            ? "text"
                                            : "password"
                                        }
                                        className="form-control"
                                        placeholder={t("oldPassword")}
                                        {...register("Oldpassword")}
                                      />
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/lock.svg"
                                        }
                                      />
                                      <img
                                        onClick={toggleoldPasswordVisibility}
                                        src={
                                          isoldPasswordVisible
                                            ? process.env.PUBLIC_URL +
                                            "/assets/images/eye-close-line.svg"
                                            : process.env.PUBLIC_URL +
                                            "/assets/images/eye_open.svg"
                                        }
                                        alt={
                                          isoldPasswordVisible
                                            ? "Hide password"
                                            : "Show password"
                                        }
                                        className="close_eye"
                                      />
                                    </div>
                                    {errors.Oldpassword && (
                                      <span className="text-danger">
                                        {" "}
                                        {errors.Oldpassword.message}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-12 mb-3">
                                    <div className="form-group left_fixed">
                                      <input
                                        type={
                                          isPasswordVisible
                                            ? "text"
                                            : "password"
                                        }
                                        className="form-control"
                                        placeholder={t("newPassword")}
                                        {...register("NewPassword")}
                                      />
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/lock.svg"
                                        }
                                      />
                                      <img
                                        onClick={togglePasswordVisibility}
                                        src={
                                          isPasswordVisible
                                            ? process.env.PUBLIC_URL +
                                            "/assets/images/eye-close-line.svg"
                                            : process.env.PUBLIC_URL +
                                            "/assets/images/eye_open.svg"
                                        }
                                        alt={
                                          isPasswordVisible
                                            ? "Hide password"
                                            : "Show password"
                                        }
                                        className="close_eye"
                                      />
                                    </div>
                                    {errors.NewPassword && (
                                      <span className="text-danger">
                                        {" "}
                                        {errors.NewPassword.message}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-12 mb-3">
                                    <div className="form-group left_fixed">
                                      <input
                                        type={
                                          isconfirmPasswordVisible
                                            ? "text"
                                            : "password"
                                        }
                                        className="form-control"
                                        placeholder={t("confirmPassword")}
                                        {...register("Confirm_Password2")}
                                      />
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/lock.svg"
                                        }
                                      />
                                      <img
                                        onClick={
                                          toggleconfirmPasswordVisibility
                                        }
                                        src={
                                          isconfirmPasswordVisible
                                            ? process.env.PUBLIC_URL +
                                            "/assets/images/eye-close-line.svg"
                                            : process.env.PUBLIC_URL +
                                            "/assets/images/eye_open.svg"
                                        }
                                        alt={
                                          isconfirmPasswordVisible
                                            ? "Hide password"
                                            : "Show password"
                                        }
                                        className="close_eye"
                                      />
                                    </div>
                                    {errors.Confirm_Password2 && (
                                      <span className="text-danger">
                                        {" "}
                                        {errors.Confirm_Password2.message}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-12 mb-3">
                                    <div className="form-group left_fixed">
                                      <button
                                        type="submit"
                                        className="btn btn-theme"
                                      >
                                        {t("update")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </>
  );
};

export default Change_password;
