import React, { useRef } from "react";
import {
  unstable_registerShapeModel,
  unstable_registerToolbarComponent,
  unstable_registerShapeComponent,
} from "polotno/config";
import { Button, Alignment, Navbar } from "@blueprintjs/core";
import ColorPicker from "polotno/toolbar/color-picker";
import { observer } from "mobx-react-lite";
import { Line } from "react-konva";
import { types } from "mobx-state-tree";

// Define pen model
unstable_registerShapeModel({
  type: "pen",
  points: types.array(types.number), // Update the definition for points
  stroke: "black",
  strokeWidth: 2,

});
// Toolbar component for pen
const PenToolbar = observer(({ store }) => {
  const element = store.selectedElements[0];

  return (
    <Navbar.Group align={Alignment.LEFT}>
      <ColorPicker
        value={element.stroke}
        onChange={(stroke) => element.set({ stroke })}
        store={store}
      />
      <input
        type="number"
        value={element.strokeWidth}
        onChange={(e) =>
          element.set({ strokeWidth: parseInt(e.target.value, 10) })
        }
        style={{ width: "50px", marginLeft: "10px" }}
      />
    </Navbar.Group>
  );
});

// Register toolbar component
unstable_registerToolbarComponent("pen", PenToolbar);

// Canvas component for pen
const PenElement = observer(({ element }) => {
  // const ref = useRef(null);

  // const handleMouseDown = (e) => {
  //   const stage = ref.current.getStage();
  //   const position = stage.getPointerPosition();
  //   element.points.push(position.x, position.y); // Add starting point
  // };

  // const handleMouseMove = (e) => {
  //   if (e.evt.buttons !== 1) return; // Only draw while mouse button is pressed
  //   const stage = ref.current.getStage();
  //   const position = stage.getPointerPosition();
  //   element.points.push(position.x, position.y); // Add current point
  // };

  return (
    <Line
      // ref={ref}
      points={element.points}
      stroke={element.stroke}
      strokeWidth={element.strokeWidth}
      // onMouseDown={handleMouseDown}
      // onMouseMove={handleMouseMove}
    />
  );
});

// Register canvas component
unstable_registerShapeComponent("pen", PenElement);
