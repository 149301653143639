import React, { useCallback, useEffect, useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useTranslation } from "react-i18next";
import { getKeyword, getLandingTheme, getTheme, getTrendingTemplate } from "../../../api/apiHandler";
import { useNavigate, useSearchParams } from "react-router-dom";
import TemplateDetailsModal from "../../../components/TemplateDetailsModal";
import ShareModal from "../../../components/ShareModal";
import { getToken } from "../../../Common/LocalStorageService";

import leftImage from "../../../Common/left-white.svg";
import rightImage from "../../../Common/right-white.svg";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import VideoPlay from "../../../components/VideoPlay";
import Lottie from "react-lottie";
import loadingAnimation from '../../Editor/loder.json'
// import './styles.css';
const VideoComponent = ({ item, setShareId, setIsShareModalOpen, editTemplate, setTemplateData, setIsTemplateDetailsModalOpen }) => {
  const videoRef = useRef(null);

  // Event handlers for hover
  const handleMouseEnter = (e) => {
    console.log('handleMouseEnter: ');
    if (videoRef && videoRef.current) {
      // setIsPlaying(index);
      videoRef.current.play(); // Start playing the video
    }
  };

  const handleMouseLeave = (e) => {
    console.log('handleMouseEnter: 55664564');
    if (videoRef && videoRef.current) {
      // setIsPlaying(-1);
      videoRef.current.pause(); // Pause the video
    }
  };
  useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.load(); // Ensure video is loaded
    }
  }, [item?.video_url]);
  return (
    // <div
    //   key={"template_in" + item?.name + "_" + index}
    //   // className={`item ${item?.orientation == "landscape" ? "landscapeWidth" : "templateWidth"
    //   //   }`}
    //   className="item"
    //   style={{ padding: "3%" }}
    // >
    <>
      {item?.is_video == 1 && (
        <a className="video_show">
          <img
            src={
              process.env.PUBLIC_URL +
              "/assets/images/icon/video-pause.svg"
            }
          />
        </a>
      )}
      <div className="overlay_hover"
        onMouseEnter={(e) => { handleMouseEnter(e) }}
        onMouseLeave={(e) => { handleMouseLeave(e) }}>
        <a className="template_image_slider ">
          {/* position-relative */}
          {item?.is_video == 1 && item?.video_url ? (
            <video
              src={item?.video_url}
              ref={videoRef}
              loop
              muted
              playsInline
              preload="auto"
              style={{ objectFit: "contain", height: "100%", width: "100%" }}
            ></video>
          ) : (
            <img src={item?.thumbnail} />
          )}
        </a>
        <div className="overlay_div">
          <div
            className={`d-flex justify-content-end`}
          >
            {/* {item?.is_video == 1 && (
            <a>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/icon/video-pause.svg"
                }
              />
            </a>
          )} */}
            <div className="top_li">
              <a
                onClick={() => {
                  setShareId(item?.id);
                  setIsShareModalOpen(true);
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/share.svg"}
                />
              </a>
              <a
                onClick={() => {
                  editTemplate(item);
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/edit.svg"}
                />
              </a>
            </div>
          </div>
          <div className="bottom_li">
            <div
              className="user_name cursor-pointer"
            // onClick={() => userinfo(item?.user_id)}
            >
              {/* <img src={item?.profile_image} className="ProfileImage2" /> */}
              {/* <h6 className="user-name-h6">{item?.full_name}</h6> */}
            </div>
            <a
              onClick={() => {
                setTemplateData(item);
                setIsTemplateDetailsModalOpen(true);
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/assets/images/info.svg"}
                className="infoImg"
              />
            </a>
          </div>
        </div>
      </div>
    </>
    // </div>
  );
}

const SimmerEffectComponent = () => {
  const arr = [1, 2, 3, 4, 5, 6]

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 carousel-container">

          <OwlCarousel
            nav={true}
            // navText={["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"]}
            navText={["", ""]}
            // navElement="div"
            // navContainerClass="owl-nav"
            // navClass={['owl-prev', 'owl-next']}
            // key={carouselKey} // Force re-render when carouselKey changes
            className="owl-carousel owl-theme template_slider"
            autoWidth={true}
            dots={true}
            responsive={{
              0: {
                items: 2,
              },
              600: {
                items: 3,
              },
              1100: {
                items: 4,
              },
              1300: {
                items: 6,
              },
            }}
          >
            {arr?.map((item, index) => (
              <div key={item.id} className="item"
              >
                <div
                  key={"template_in" + item?.name + "_" + index}
                  className={`item ${item?.orientation == "landscape"
                    ? "landscapeWidth"
                    : "templateWidth"
                    }`}
                  style={{ padding: "3%" }}
                // style={{ height: "250px", width: "250px", background: "rgb(240, 240, 240)", margin: "0 10px" }}
                // style={{ height: "250px", width: "250px", background: "rgb(240, 240, 240)", margin: "0 10px" }}
                // onMouseEnter={(e) => { handleMouseEnter(e, index) }}
                // onMouseLeave={(e) => { handleMouseLeave(e, index) }}

                >
                  <div className="overlay_hover"
                    style={{ height: "250px", background: "rgb(240, 240, 240)" }}

                  >
                    <div className=""
                      style={{ background: "rgb(240, 240, 240)", margin: "0 10px" }}
                    >
                    </div>
                  </div>
                </div>
                {/* <ComponentDDD item={item} index={index} /> */}
              </div>
            ))}
          </OwlCarousel>
          {/* <button onClick={goToNext} className="next-btn">Next</button> */}

        </div>
      </div>
    </div>
  )
}
const DefaultTemp = ({ setOpenLogin }) => {
  const { t, i18n } = useTranslation();

  const [template, setTemplate] = useState([]);
  console.log('template145: ', template);
  const [theme, setTheme] = useState([]);
  const [indexdata, setIndexData] = useState();
  console.log('theme: ', theme);
  const [isTemplateDetailsModalOpen, setIsTemplateDetailsModalOpen] =
    useState(false);
  const [shareId, setShareId] = useState();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [templateData, setTemplateData] = useState({});
  const [kerwordId, setKerwordId] = useState(0)
  const [isKeywordClick, setIsKeywordClick] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const addQueryParams = (id) => {
    const params = new URLSearchParams(window.location.search);
    params.set("c2hhcmU", id);

    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params}`
    );
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const navigate = useNavigate();
  const editTemplate = useCallback(
    (data) => {
      if (getToken()) {
        navigate(`/copy-template/${data?.id}`);
      } else {
        addQueryParams(data?.id);
        setOpenLogin(true);
      }
    },
    [navigate, setOpenLogin]
  );
  const handleTrendingTemplateApi = useCallback(() => {
    getTrendingTemplate({}).then((response) => {
      if (response.code === 200) {
        setIsKeywordClick(false)
        setTemplate(response.data);
      }
      setIsLoading(false)
    });
  }, [])
  useEffect(() => {
    // getTrendingTemplate({}).then((response) => {
    //   if (response.code === 200) {
    //     setTemplate(response.data);
    //   }
    // });
    setIsLoading(true)
    handleTrendingTemplateApi();
    getLandingTheme({}).then((response) => {
      console.log('response888: ', response);
      if (response.code === 200) {
        setTheme(response.data);
      }
    });
  }, []);
  const ComponentDDD = useCallback((props) => {
    const { item, index } = props;
    console.log("item147: ", item, item?.is_video);
    return (
      // <div
      //   key={"template_in" + item?.name + "_" + index}
      //   // className={`item ${item?.orientation == "landscape" ? "landscapeWidth" : "templateWidth"
      //   //   }`}
      //   className="item"
      //   style={{ padding: "3%" }}
      // >
      <div className="overlay_hover">
        <a className="template_image_slider ">
          {/* position-relative */}
          {item?.is_video == 1 && item?.video_url ? (
            <video
              src={item?.video_url}
              // controls
              autoPlay
              loop
              muted
              style={{ objectFit: "contain", height: "100%", width: "100%" }}
            ></video>
          ) : (
            <img src={item?.thumbnail} />
          )}
        </a>
        <div className="overlay_div">
          <div
            className={`d-flex ${item?.is_video == 1
              ? "justify-content-between"
              : "justify-content-end"
              }`}
          >
            {item?.is_video == 1 && (
              <a>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icon/video-pause.svg"
                  }
                />
              </a>
            )}
            <div className="top_li">
              <a
                onClick={() => {
                  setShareId(item?.id);
                  setIsShareModalOpen(true);
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/share.svg"}
                />
              </a>
              <a
                onClick={() => {
                  editTemplate(item);
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/edit.svg"}
                />
              </a>
            </div>
          </div>
          <div className="bottom_li">
            <div
              className="user_name cursor-pointer"
            // onClick={() => userinfo(item?.user_id)}
            >
              {/* <img src={item?.profile_image} className="ProfileImage2" /> */}
              <h6 className="user-name-h6">{item?.full_name}</h6>
            </div>
            <a
              onClick={() => {
                setTemplateData(item);
                setIsTemplateDetailsModalOpen(true);
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/assets/images/info.svg"}
                className="infoImg"
              />
            </a>
          </div>
        </div>
      </div>
      // </div>
    );
  }, []);
  const handleTrendingTemplate = useCallback((kerword_id, index) => {
    setKerwordId((key) => key + 1);
    setIndexData(index)
    getTrendingTemplate({ kerword_id: kerword_id }).then((response) => {
      if (response.code === 200) {
        setIsKeywordClick(false)
        setTemplate(response.data);
      } else {
        setTemplate([]);
      }
    });
  }, []);
  useEffect(() => {
    setKerwordId((key) => key + 1);
  }, [template])
  console.log("template:====> ", template);
  return (
    <>
      <section className="section_template InstagramPostSection">
        <div className="container">
          <div className="row ">
            <div className="col-12">
              <div className="title-text text-center">
                <h1
                  dangerouslySetInnerHTML={{ __html: t("CreatetitleNew") }}
                ></h1>
                <p className="mx-auto">{t("Createsubtitle")}</p>
              </div>
            </div>
          </div>
        </div>

        <section className="InstagramPostSection checkkk mb-4">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {isLoading ? (
                  <SimmerEffectComponent />
                ) : (
                  theme && theme.length > 0 && (
                    <OwlCarousel
                      key={"theme-keyword"}
                      className=" default-template-custom owl-carousel"
                      // loop
                      autoWidth={true}
                      margin={20}
                      // center={true}
                      nav
                      navText={[
                        `<img src="${leftImage}" alt="Prev" class="lanndingOwlBtn"/>`,
                        `<img src="${rightImage}" alt="Next" class="lanndingOwlBtn"/>`,
                      ]}
                      dots={false}
                    >
                      {theme?.map((item, index) => {
                        return (
                          <div
                            className="InstagramPostItem"
                            key={"keyword" + item.id}
                            onClick={() => {
                              setIsKeywordClick(true)
                              if (indexdata == index) {
                                setIndexData()
                                handleTrendingTemplateApi();
                              } else {
                                handleTrendingTemplate(item?.id, index);
                              }
                            }}
                          >
                            <a
                              href="javascript:void(0)"
                              className="InstagramPostItemLink"
                              style={{ fontWeight: indexdata == index ? "700" : "500" }}
                            >
                              {item.name}
                            </a>
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  )
                )}
                {/* old code with oute simmer effect  */}
                {/* {theme && theme.length > 0 && (
                  <OwlCarousel
                    key={"theme-keyword"}
                    className=" default-template-custom owl-carousel"
                    // loop
                    autoWidth={true}
                    margin={20}
                    // center={true}
                    nav
                    navText={[
                      `<img src="${leftImage}" alt="Prev" class="lanndingOwlBtn"/>`,
                      `<img src="${rightImage}" alt="Next" class="lanndingOwlBtn"/>`,
                    ]}
                    dots={false}
                  // responsive={{
                  //   0: {
                  //     items: 2, // Show one item on screens smaller than 600px
                  //     margin: 0,
                  //   },
                  //   320: {
                  //     items: 2, // Show two items on screens between 600px and 900px
                  //   },
                  //   600: {
                  //     items: 4, // Show two items on screens between 600px and 900px
                  //   },
                  //   900: {
                  //     items: 6, // Show three items on screens larger than 900px
                  //     margin: 20,
                  //   },
                  //   1200: {
                  //     items: 6, // Show three items on screens larger than 70px
                  //     margin: 20,
                  //   },
                  //   1440: {
                  //     items: 6, // Show three items on screens larger than 900px
                  //     margin: 20,
                  //   },
                  // }}
                  >
                    {theme?.map((item, index) => {
                      return (
                        <div
                          className="InstagramPostItem"
                          key={"keyword" + item.id}
                          onClick={() => {
                            setIsKeywordClick(true)
                            if (indexdata == index) {
                              setIndexData()
                              handleTrendingTemplateApi();
                            } else {
                              handleTrendingTemplate(item?.id, index);
                            }
                          }}
                        >
                          <a
                            href="javascript:void(0)"
                            className="InstagramPostItemLink"
                            style={{ fontWeight: indexdata == index ? "700" : "500" }}
                          >
                            {item.name}
                          </a>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                )} */}
                {/* <Swiper
                  slidesPerView={5}
                  // slidesPerView={'auto'}
                  // centeredSlides={true}
                  spaceBetween={30}
                  // pagination={{
                  //   clickable: true,
                  // }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                >
                  {theme?.map((item, index) => {
                    return (
                      <SwiperSlide>
                        <div
                          className="InstagramPostItem"
                          key={"keyword" + item.id}
                          onClick={() => {
                            handleTrendingTemplate(item?.id);
                          }}
                        >
                          <a
                            href="javascript:void(0)"
                            className="InstagramPostItemLink"
                            onClick={() => { }}
                          >
                            {item.name}
                          </a>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper> */}
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          <div className={`row ${isKeywordClick && "d-flex justify-content-center"}`}>
            {isKeywordClick ? (
              // < Lottie options={defaultOptions} height={50} width={80} />
              <img src={process.env.PUBLIC_URL + "/assets/images/loaderOld.gif"} style={{ height: "100%", width: "100px" }} alt=''></img>
            ) : (
              <div className="col-12">
                {/* <div className="owl-carousel owl-theme template_slider"> */}

                {template && template?.length > 0 && (
                  <OwlCarousel
                    key={kerwordId}
                    className="owl-carousel owl-theme template_slider2 trending_templates"
                    // loop
                    // autoplay
                    // nav
                    margin={20}
                    dots={true}
                    // autoWidth={true}
                    // navText={["<img src={process.env.PUBLIC_URL +'assets/images/left.svg'>", "<img src={process.env.PUBLIC_URL +'assets/images/right.svg'>"]}
                    responsive={{
                      0: {
                        items: 1, // Show one item on screens smaller than 600px
                        dots: false,
                        margin: 0,
                      },
                      320: {
                        items: 1, // Show two items on screens between 600px and 900px
                        dots: false,
                      },
                      600: {
                        items: 3, // Show two items on screens between 600px and 900px
                      },
                      900: {
                        items: 4, // Show three items on screens larger than 900px
                        margin: 20,
                      },
                      1200: {
                        items: 5, // Show three items on screens larger than 900px
                        margin: 20,
                      },
                      1440: {
                        items: 5, // Show three items on screens larger than 900px
                        margin: 20,
                      },
                    }}
                  >
                    {template.length > 0 &&
                      template?.map((item, index) => {
                        return (
                          <div
                            key={"template" + item?.name}
                            className="item item-home2 position-relative"
                          >
                            {/* <img src={item?.thumbnail} /> */}
                            {/* <VideoPlay item={item} index={index} setShareId={setShareId} setIsShareModalOpen={setIsShareModalOpen} editTemplate={editTemplate} setTemplateData={setTemplateData} setIsTemplateDetailsModalOpen={setIsTemplateDetailsModalOpen} /> */}
                            {/* <ComponentDDD item={item} index={index} /> */}
                            <VideoComponent item={item} setShareId={setShareId} setIsShareModalOpen={setIsShareModalOpen} editTemplate={editTemplate} setTemplateData={setTemplateData} setIsTemplateDetailsModalOpen={setIsTemplateDetailsModalOpen} />
                          </div>
                        );
                      })}
                    {/* <div className="item mx-2">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/slider/2.png"}
                />
              </div>
              <div className="item mx-2">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/slider/3.png"}
                />
              </div>
              <div className="item mx-2">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/slider/4.png"}
                />
              </div>
              <div className="item mx-2">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/slider/5.png"}
                />
              </div>
              <div className="item mx-2">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/slider/6.png"}
                />
              </div>
              <div className="item mx-2">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/slider/4.png"}
                />
              </div>
              <div className="item mx-2">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/slider/2.png"}
                />
              </div>
              <div className="item mx-2">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/slider/3.png"}
                />
              </div>
              <div className="item mx-2">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/slider/4.png"}
                />
              </div>
              <div className="item mx-2">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/slider/5.png"}
                />
              </div>
              <div className="item mx-2">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/slider/6.png"}
                />
              </div>
              <div className="item mx-2">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/slider/2.png"}
                />
              </div>
              <div className="item mx-2">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/slider/3.png"}
                />
              </div>
              <div className="item mx-2">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/slider/4.png"}
                />
              </div>
              <div className="item mx-2">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/slider/5.png"}
                />
              </div>
              <div className="item mx-2">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/slider/6.png"}
                />
              </div> */}
                    {/* </div> */}
                  </OwlCarousel>
                )}
              </div>
            )}
          </div>
        </div>
      </section>
      <div classname="container">
        <div classname="row">
          <div
            classname="col-md-12 col-sm-12 col-12 mb-5 text-center"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "5px",
            }}
          >
            <button
              class="btn btn-create"
              type="button"
              onClick={() => {
                navigate("/home");
              }}
            >
              {t("seeMoreDesigns")}
            </button>
          </div>
        </div>
      </div>
      {isShareModalOpen && (
        <ShareModal
          isShareModalOpen={isShareModalOpen}
          shareId={shareId}
          setIsShareModalOpen={setIsShareModalOpen}
        />
      )}
      {isTemplateDetailsModalOpen && (
        <TemplateDetailsModal
          data={templateData || {}}
          setTemplateData={setTemplateData}
          isTemplateDetailsModalOpen={isTemplateDetailsModalOpen}
          setShareId={setShareId}
          setIsTemplateDetailsModalOpen={setIsTemplateDetailsModalOpen}
          setIsShareModalOpen={setIsShareModalOpen}
          setIsLogin={setOpenLogin}
        />
      )}
    </>
  );
};

export default DefaultTemp;
