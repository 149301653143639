// ErrorBoundaryFallback.js
import React from "react";

function ErrorBoundaryFallback({ error, resetErrorBoundary }) {
  return (
    <div>
      <h2>Something went wrong:</h2>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

export default ErrorBoundaryFallback;
