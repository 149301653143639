import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import checkValidation from "../../../validation/ValidationSchema";

import { Helmet } from "react-helmet";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

import { PhoneNumberUtil } from "google-libphonenumber";

import OtpVerify from "./components/OtpVerify";
import { showPromiseNotification } from "../../../Common/notification";
import { signup } from "../../../api/apiHandler";
import moment from "moment";

const Signup = () => {
  const [isClicked, setIsClicked] = useState(false);
  const [phone, setPhone] = useState("");

  const { t, i18n } = useTranslation();
  const [otpverify, setOtpverify] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isconfirmPasswordVisible, setconfirmPasswordVisible] = useState(false);
  const [onSuccessSignup, setSuccessSignUp] = useState();
  const schema = checkValidation([
    "Fullname",
    "Email",
    // "PhoneNumber",
    // "DateOfBirth",
    "Password",
    "Confirm_Password",
    "AcceptTerms",
  ]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    setValue,
    watch,
    formState: { isSubmitted },
  } = useForm({
    resolver: yupResolver(schema),
  });
  // console.log('errors: ', errors);
  const phoneUtil = PhoneNumberUtil.getInstance();
  console.log("watch:===> ", watch());

  const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  const handleFocus = () => {
    setIsClicked(true);
  };

  const handleBlur = () => {
    setIsClicked(false);
  };

  const onSubmit = (data) => {
    // const phoneNumber = phoneUtil.parseAndKeepRawInput(data?.PhoneNumber);
    const payload = {
      full_name: data?.Fullname,
      email: data?.Email,
      // country_code: `+${phoneNumber.getCountryCode()}`,
      // phone: phoneNumber.getNationalNumber().toString(),
      // dob: moment(data?.DateOfBirth).format(),
      password: data?.Password,
      device_name: "website",
      device_token: "-",
      device_type: "W",
    };

    const myPromise = new Promise((resolve, reject) =>
      signup(payload).then((response) => {
        console.log("response: ", typeof response, response);
        if (response.code === 200) {
          setSuccessSignUp(response?.data);
          openDashboardModal();
          resolve(response);
        } else {
          reject(response);
        }
      })
    );
    showPromiseNotification(myPromise);
  };

  const openDashboardModal = () => {
    setOtpverify(true);
  };

  const closeDashboardModal = () => {
    setOtpverify(false);
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const toggleconfirmPasswordVisibility = () => {
    setconfirmPasswordVisible((prevState) => !prevState);
  };

  return (
    <>
      <Helmet>
        <style type="text/css">
          {`
            html {
              height: 100%;
              overflow-y: auto;
            }
            body {
              height: 100%;
              margin: 0; / To remove default margin /
            }
            main {
              height: 100%;
            }
          `}
        </style>
      </Helmet>

      <main className="h-100">
        <section className="about_sction section_signup   h-100" id="about">
          <div className="container h-100">
            <div className="row  h-100">
              <div className="col-lg-7 col-md-6 col-sm-6 col-12 h-100 d-none d-lg-block">
                <div className="about_mobile_banner">
                  <img
                    alt=""
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/signup_illustrate.png"
                    }
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-6 col-12 ms-auto d-flex flex-column">
                <div className="login_header">
                  <Link className="cursor-pointer" to="/">
                    <img
                      alt=""
                      src={
                        process.env.PUBLIC_URL + "/assets/images/left_arrow.svg"
                      }
                    />{" "}
                    {t("back")}
                  </Link>
                  <label>
                    {t("alreadyHaveAccount")}{" "}
                    <Link to="/login"> {t("loginButton")}</Link>
                  </label>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="title-text">
                    <h1 className="mb-2">{t("signupButton")}</h1>
                    <p>{t("enterDetails")}</p>
                    <div className="login_form">
                      <div className="row">
                        <div className="col-12 mb-2">
                          <div className="form-group left_fixed">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("fullName")}
                              {...register("Fullname")}
                            />
                            <img
                              alt=""
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/account.svg"
                              }
                            />
                          </div>
                          {errors.Fullname && (
                            <span class="text-danger">
                              {" "}
                              {errors.Fullname.message}
                            </span>
                          )}
                        </div>
                        <div className="col-12 mb-2">
                          <div className="form-group left_fixed">
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("email")}
                              {...register("Email")}
                            />
                            <img
                              alt=""
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/email.svg"
                              }
                            />
                          </div>
                          {errors.Email && (
                            <span class="text-danger">
                              {" "}
                              {errors.Email.message}
                            </span>
                          )}
                        </div>

                        {/* <div className='col-lg-3 col-md-4 col-sm-4 col-5 mb-2'> */}
                        {/* <div className="col-12 mb-2">

                          <PhoneInput
                            defaultCountry="ua"
                            value={phone}
                            className="form-group"
                            inputProps={{
                              className: "form-control",
                              name: "PhoneNumber",
                            }}
                            inputStyle={{
                              borderTopLeftRadius: "0",
                              borderBottomLeftRadius: "0",
                              borderLeft: "0",
                              paddingLeft: "0",
                            }}
                            countrySelectorStyleProps={{
                              buttonContentWrapperClassName:
                                "form-control h-100",
                              buttonContentWrapperStyle: {
                                borderTopRightRadius: "0",
                                borderBottomRightRadius: "0",
                                border: "1px solid #E4EAF7",
                                paddingRight: "5px",
                                borderRight: "0",
                              },

                              buttonClassName: "border-0 h-100",
                            }}
                            onChange={(phone) => {
                              console.log("phone:===> ", phone);
                              setPhone(phone);
                              setValue("PhoneNumber", phone);
                              const errorPhone = isPhoneValid(phone);
                              console.log("errorPhone: ", errorPhone);
                              if (errorPhone) {
                                clearErrors("PhoneNumber");
                                console.log("--------------------hererere");
                              } else {
                                setError("PhoneNumber", {
                                  type: "phoneInvalid",
                                  message: i18n.t(`phoneInvalid`),
                                });
                              }
                            }}
                          />

                          {isSubmitted && errors.PhoneNumber && (
                            <span class="text-danger">
                              {" "}
                              {errors.PhoneNumber.message}
                            </span>
                          )}
                        </div> */}

                        {/* <div className="col-12 mb-2">
                          <div className="form-group left_fixed">
                            <input
                              type={isClicked ? "date" : "text"}
                              className="form-control"
                              placeholder={t("dateOfBirth")}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              {...register("DateOfBirth")}
                            />
                            <img
                              alt=""
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/cake.svg"
                              }
                            />
                          </div>
                          {errors.DateOfBirth && (
                            <span class="text-danger">
                              {" "}
                              {errors.DateOfBirth.message}
                            </span>
                          )}
                        </div> */}
                        <div className="col-12 mb-2">
                          <div className="form-group left_fixed">
                            <input
                              type={isPasswordVisible ? "text" : "password"}
                              className="form-control"
                              placeholder={t("password")}
                              {...register("Password")}
                            />
                            <img
                              alt=""
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/lock.svg"
                              }
                            />
                            <img
                              alt=""
                              onClick={togglePasswordVisibility}
                              src={
                                isPasswordVisible
                                  ? process.env.PUBLIC_URL +
                                  "/assets/images/eye-close-line.svg"
                                  : process.env.PUBLIC_URL +
                                  "/assets/images/eye_open.svg"
                              }
                              className="close_eye"
                            />
                          </div>
                          {errors.Password && (
                            <span class="text-danger">
                              {" "}
                              {errors.Password.message}
                            </span>
                          )}
                        </div>
                        <div className="col-12 mb-2">
                          <div className="form-group left_fixed">
                            <input
                              type={
                                isconfirmPasswordVisible ? "text" : "password"
                              }
                              className="form-control"
                              placeholder={t("confirmPassword")}
                              {...register("Confirm_Password")}
                            />
                            <img
                              alt=""
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/lock.svg"
                              }
                            />
                            <img
                              alt=""
                              onClick={toggleconfirmPasswordVisibility}
                              src={
                                isconfirmPasswordVisible
                                  ? process.env.PUBLIC_URL +
                                  "/assets/images/eye-close-line.svg"
                                  : process.env.PUBLIC_URL +
                                  "/assets/images/eye_open.svg"
                              }
                              className="close_eye"
                            />
                          </div>
                          {errors.Confirm_Password && (
                            <span class="text-danger">
                              {" "}
                              {errors.Confirm_Password.message}
                            </span>
                          )}
                        </div>
                        <div className="col-12 mb-2">
                          <div className="form-check check_mark">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              id="flexCheckDefault"
                              {...register("AcceptTerms")}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              {t("agreeTerms")}
                              <Link to={"/terms-conditions"}>
                                {" "}
                                {t("termsOfService")}
                              </Link>{" "}
                              {t("and")}{" "}
                              <Link to={"/privacy-policy"}>
                                {" "}
                                {t("privacyPolicy")}.
                              </Link>
                            </label>
                          </div>
                          {errors.AcceptTerms && (
                            <span class="text-danger">
                              {" "}
                              {errors.AcceptTerms.message}
                            </span>
                          )}
                        </div>
                        <div className="col-12 mb-2">
                          <div className="form-group left_fixed">
                            <button type="submit" className="btn btn-theme">
                              {t("signupButton")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>

      {otpverify && (
        <OtpVerify
          otpverify={otpverify}
          closeDashboardModal={closeDashboardModal}
          onSuccessSignup={onSuccessSignup}
        />
      )}
    </>
  );
};

export default Signup;
