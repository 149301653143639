import React, { useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import checkValidation from "../../../../validation/ValidationSchema";
import OTPInput, { ResendOTP } from "otp-input-react";
import { resendOtp, verifyOtp } from "../../../../api/apiHandler";
import { saveUserDataToLocal } from "../../../../Common/LocalStorageService";
import { useNavigate } from "react-router";
import { showPromiseNotification } from "../../../../Common/notification";

const OtpVerify = (props) => {
  const {
    otpverify,
    closeDashboardModal,
    openResetModal,
    otpData,
    isLogin = false,
    setLoginResponse,
  } = props;
  const navigate = useNavigate();

  const [OTP, setOTP] = useState("");
  const { t, i18n } = useTranslation();
  const inputs = useRef([]);
  const schema3 = checkValidation(["OTPVerification"]);
  const {
    register: register3,
    handleSubmit: handleSubmit3,
    reset: reset3,
    formState: { errors: errors3 },
    watch,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema3),
  });
  console.log("watch: ", watch());
  const renderButton = (buttonProps) => {
    return (
      <>
        <label {...buttonProps} className="">
          {t("didntReceive")}
          {buttonProps.remainingTime === 0 ? (
            <a
              className="text-theme fw-bold"
              onClick={() => {
                handleResendOtp();
              }}
            >
              {" "}
              {t("resend")}
            </a>
          ) : (
            `   Please wait for ${buttonProps.remainingTime} sec`
          )}
        </label>
      </>
    );
  };
  const renderTime = () => React.Fragment;
  const onSubmit3 = (data) => {
    console.log("data: ", data);
    const payload = {
      email: otpData?.email,
      otp: data?.OTPVerification,
    };
    const myPromise = new Promise((resolve, reject) =>
      verifyOtp(payload).then((response) => {
        if (response.code == 200) {
          saveUserDataToLocal(response.data);
          resolve(response);
          if (isLogin) {
            navigate("/home");
          } else {
            setLoginResponse(response.data);
            closeDashboardModal();
            openResetModal();
            reset3();
          }
        } else {
          reject(response);
        }
      })
    );
    showPromiseNotification(myPromise);
  };
  const handleResendOtp = (data) => {
    console.log("data: ", data);
    const payload = {
      email: otpData?.email,
    };
    const myPromise = new Promise((resolve, reject) =>
      resendOtp(payload).then((response) => {
        if (response.code == 200) {
          resolve(response);
        } else {
          reject(response);
        }
      })
    );
    showPromiseNotification(myPromise);
  };

  return (
    <>
      <div
        className={`modal fade ${otpverify ? "show" : ""}`}
        id="otp"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style={{ display: otpverify ? "block" : "none" }}
      >
        {/* <div className="modal" tabIndex={-1} id="otp"> */}
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="custom_btn"
                onClick={closeDashboardModal}
                aria-label="Close"
              >
                <img
                  alt=""
                  src={process.env.PUBLIC_URL + "/assets/images/close.svg"}
                />
              </button>
              <div className="modal_forgot">
                <h6>{t("verifyOTP")}</h6>
                <p className="text-center w-100 mb-1">{otpData?.email}</p>
                <p>{t("sentOTPMessage")}</p>
                <form onSubmit={handleSubmit3(onSubmit3)}>
                  <div className="login_form mt-4">
                    <div className="row">
                      <div className="col-12 mb-3 text-center ">
                        <div className="form-group otp_fixed mb-3">
                          {/* {[0, 1, 2, 3].map((_, index) => (
                            <input
                              key={index}
                              ref={(el) => (inputs.current[index] = el)}
                              type="text"
                              className="form-control"
                              placeholder="*"
                              maxLength="1"
                              onChange={(e) => handleInputChange(index, e)}
                              {...register3("OTPVerification")}
                            />
                          ))} */}

                          <OTPInput
                            className="form_otp d-flex justify-content-evenly align-items-center"
                            value={OTP}
                            onChange={(otp) => {
                              setOTP(otp);
                              setValue("OTPVerification", otp);
                              if (otp.length === 4) {
                                clearErrors("OTPVerification");
                              }
                            }}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            inputStyles={{
                              width: "67px",
                              height: "58px",
                              background: "#FFFFFF",
                              //   border: "2px solid #29ABE2",
                              borderRadius: "15px",
                              color: "rgba(79, 78, 78, 1)",
                              borderImageSource:
                                "linear-gradient(132.73deg, #FF7100 0%, #3A0FA6 100%)",
                              borderImageSlice: 1,
                              borderWidth: "2px",
                              borderStyle: "solid",
                            }}

                            // border-image-source: linear-gradient(132.73deg, #FF7100 0%, #3A0FA6 100%);
                          />
                        </div>
                        {errors3.OTPVerification && (
                          <span className="text-danger">
                            {errors3.OTPVerification.message}
                          </span>
                        )}
                      </div>
                      <div className="col-12 mb-3 text-center">
                        <div className="form-group left_fixed justify-content-center mb-3">
                          <button
                            type="submit"
                            className="btn btn-theme w-auto"
                          >
                            {t("verifyButton")}
                          </button>
                        </div>
                        {/* <ResendOTP
                          onResendClick={() => console.log("Resend clicked")}
                        /> */}
                        <ResendOTP
                          style={{ justifyContent: "center" }}
                          renderButton={renderButton}
                          renderTime={renderTime}
                        />
                        {/* ;
                        <label>
                          {t("didntReceive")}
                          <a
                            className="text-theme fw-bold"
                            onClick={() => {
                              handleResendOtp();
                            }}
                          >
                            {" "}
                            {t("resend")}
                          </a>
                        </label> */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default OtpVerify;
