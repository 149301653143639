import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Rating } from "react-simple-star-rating";
import * as Yup from "yup";
import { showPromiseNotification } from "../Common/notification";
import { addReview, getReview } from "../api/apiHandler";
const RatingModal = (props) => {
  const { testiMonialModalOpen, setIsTestimonialModalOpen } = props;
  const [review, setReview] = useState({});
  console.log("review: ", review);
  const { t, i18n } = useTranslation();

  // Catch Rating value

  const schema = Yup.object().shape({
    rating: Yup.number().nullable().required(t("pleaseSelectRating")),
    feedback: Yup.string().required(t("feedbackRequired")),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    values: {
      rating: review?.rating || null,
      feedback: review?.feedback || "",
    },
  });
  console.log("watch: ", watch());

  const handleRating = (rate) => {
    setValue("rating", rate);
    clearErrors("rating");
  };

  const onSubmit = (data) => {
    const payload = { ...data };

    const myPromise = new Promise((resolve, reject) =>
      addReview(payload).then((response) => {
        if (response.code === 200) {
          reset();

          resolve(response);
        } else {
          reject(response);
        }
      })
    );
    showPromiseNotification(myPromise);

    reset();
    setIsTestimonialModalOpen(false);
  };

  useEffect(() => {
    getReview({ is_own: 1 }).then((response) => {
      if (response.code === 200) {
        setReview(response?.data[0]);
      }
    });
  }, []);

  return (
    <>
      <div
        className={`modal fade ${testiMonialModalOpen ? "show d-block " : ""}`}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        id="rating"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="custom_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  reset();
                  setIsTestimonialModalOpen(false);
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/close.svg"}
                />
              </button>
              <div className="modal_forgot">
                <h6>{t("addYourReview")}</h6>
                <div className="login_form mt-5">
                  <div className="row">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="col-12 mb-3 text-center">
                        <div className="form-group left_fixed d-flex justify-content-center  text-center">
                          <Rating
                            onClick={handleRating}
                            ratingValue={watch("rating")}
                            initialValue={watch("rating")}
                            size={35}
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "center",
                            }}
                            readonly={review?.id}
                            label
                            transition
                            fillColor="orange"
                            emptyColor="gray"
                            className="foo" // Will remove the inline style if applied
                          />
                        </div>
                        {errors.rating && (
                          <span className="text-center  text-danger">
                            {" "}
                            {errors.rating.message}
                          </span>
                        )}
                      </div>
                      <div className="col-12 mb-3">
                        <div className="form-group left_fixed">
                          <textarea
                            className="form-control textare_height"
                            placeholder={t("enterYourMessage")}
                            disabled={review?.id}
                            {...register("feedback")}
                          />
                          {errors.feedback && (
                            <span className="text-danger">
                              {" "}
                              {errors.feedback.message}
                            </span>
                          )}
                        </div>
                      </div>
                      {!review?.id && (
                        <div className="col-12 mb-3">
                          <div className="form-group left_fixed">
                            <button type="submit" className="btn btn-theme">
                              {t("submit")}
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {testiMonialModalOpen && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

export default RatingModal;
