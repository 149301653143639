import { createSlice } from "@reduxjs/toolkit";

const initialLanguage = localStorage.getItem('selectedLanguage') || 'EN';
const changeLanguageSlice = createSlice({
    name: 'changelanguage',
    initialState: initialLanguage,
    reducers: {
        setlanguage: (state, action) => {
            return action.payload;
        }
    },
});

export const { setlanguage } = changeLanguageSlice.actions;
export default changeLanguageSlice.reducer;

