import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import checkValidation from "../../../validation/ValidationSchema";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { login } from "../../../api/apiHandler";
import { saveUserDataToLocal } from "../../../Common/LocalStorageService";
import { showPromiseNotification } from "../../../Common/notification";

const LoginModal = (props) => {
  const {
    isLogin,
    closeLoginModal,
    forgotmodal,
    openDashboardModal,
    HandleSignupModal,
    setLoginResponse,
    setIsLoginVerification,
    state = "",
    // setIsShare
  } = props;
  console.log('state444: ', state);
  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const paramId = searchParams.get('c2hhcmU');


  const { t } = useTranslation();
  const schema = checkValidation(["Email", "OnlyPasswordRequired"]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const payload = {
      email: data?.Email,
      password: data?.Password,
      device_name: "website",
      device_token: "-",
      device_type: "W",
    };

    const myPromise = new Promise((resolve, reject) =>
      login(payload).then((response) => {
        console.log("response: ", typeof response, response);
        if (response.code == 200) {
          if (response.data.is_verify == 0) {
            setLoginResponse(payload);
            setIsLoginVerification(true);
            openDashboardModal();
            resolve(response);
          } else {
            saveUserDataToLocal(response.data);
            resolve(response);
            if (paramId) {
              navigate("/copy-template/" + paramId);
            } else if (state && state?.is_share) {
              const pathSegments = state?.path.split('/');
              const desiredPath = pathSegments.slice(2).join('/');
              navigate(desiredPath)
            } else {
              navigate("/home");
            }
          }
        } else {
          reject(response);
        }
      })
    );
    showPromiseNotification(myPromise);
  };
  return (
    <>
      <div
        className={`modal fade ${isLogin ? "show" : ""}`}
        id="login-modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style={{ display: isLogin ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="custom_btn"
                // onClick={closeLoginModal}
                onClick={() => {
                  closeLoginModal();
                  //  setIsShare(false) 
                }}
                aria-label="Close"
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/close.svg"}
                />
              </button>
              <div className="modal-login-main">
                <div className="modal-logo">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/Logosvg1.svg"}
                    className="modal-logo-img"
                  />
                </div>
                <h6 className="modal-heading">{t("signinToCraetivo")}</h6>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ul className="link-box-ul">
                    {/* <li className="link-box-li">
                      <a href="#" className="google-link">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icon/google-icon.svg"
                          }
                          alt="google-icon"
                          className="login-modal-icon"
                        />
                        {t("continueWithGoogle")}
                      </a>
                    </li>
                    <li className="link-box-li">
                      <a href="#" className="facebook-link">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icon/fb-icon.svg"
                          }
                          alt="facebook-icon"
                          className="login-modal-icon"
                        />
                        {t("continueWithFB")}
                      </a>
                    </li>
                    <li className="link-box-li">
                      <div className="or-box">
                        <p className="continue-or-text">OR</p>
                      </div>
                    </li> */}

                    <li className="link-box-li">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("email")}
                        {...register("Email")}
                      />
                      {errors.Email && errors.Email.message != undefined && (
                        <span className="text-danger"> {errors.Email.message}</span>
                      )}
                    </li>
                    <li className="link-box-li">
                      <input
                        type={"password"}
                        className="form-control"
                        placeholder={t("password")}
                        {...register("Password")}
                      />
                      {errors.Password && (
                        <span className="text-danger">
                          {" "}
                          {errors.Password.message}
                        </span>
                      )}
                    </li>

                    <li className="link-box-li">
                      <div className="form-group left_fixed">
                        <button type="submit" className="btn btn-theme">
                          {t("loginButton")}
                        </button>
                      </div>
                    </li>
                    <li className="link-box-li">
                      <a
                        className="forgot-pass-link"
                        onClick={(e) => forgotmodal(e)}
                      >
                        {t("forgotPassword")}
                      </a>
                    </li>
                    <li className="link-box-li">
                      <p className="dont-text">
                        {t("dontHaveAccount")}{" "}
                        <a
                          onClick={(e) => {
                            HandleSignupModal(e);
                          }}
                          className="sign-up-link d-inline-block"
                        >
                          {t("signupButton")}
                        </a>
                      </p>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default LoginModal;
