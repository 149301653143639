import React from 'react'
import OwlCarousel from "react-owl-carousel";

function ShimmerEffect({ tempData, isLoading }) {
    console.log('tempData: ', tempData);
    const arr = [1, 2, 3, 4, 5, 6]
    if (isLoading) {
        return
    }
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 mb-2 d-flex justify-content-between align-items-center">
                    {/* flex-wrap */}
                    <h2 className="section_title" style={{ height: "3vh", width: "15%", background: "rgb(240, 240, 240)" }}></h2>
                </div>

                <div className="col-12 carousel-container">
                    {tempData?.length && (
                        <>
                            <OwlCarousel
                                nav={true}
                                // navText={["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"]}
                                navText={["", ""]}
                                // navElement="div"
                                // navContainerClass="owl-nav"
                                // navClass={['owl-prev', 'owl-next']}
                                // key={carouselKey} // Force re-render when carouselKey changes
                                className="owl-carousel owl-theme template_slider"
                                autoWidth={true}
                                dots={true}
                                responsive={{
                                    0: {
                                        items: 2,
                                    },
                                    600: {
                                        items: 3,
                                    },
                                    1100: {
                                        items: 4,
                                    },
                                    1300: {
                                        items: 6,
                                    },
                                }}
                            >
                                {arr?.map((item, index) => (
                                    <div key={item.id} className="item"
                                    >
                                        <div
                                            key={"template_in" + item?.name + "_" + index}
                                            className={`item ${item?.orientation == "landscape"
                                                ? "landscapeWidth"
                                                : "templateWidth"
                                                }`}
                                            style={{ padding: "3%" }}
                                        // style={{ height: "250px", width: "250px", background: "rgb(240, 240, 240)", margin: "0 10px" }}
                                        // style={{ height: "250px", width: "250px", background: "rgb(240, 240, 240)", margin: "0 10px" }}
                                        // onMouseEnter={(e) => { handleMouseEnter(e, index) }}
                                        // onMouseLeave={(e) => { handleMouseLeave(e, index) }}

                                        >
                                            <div className="overlay_hover"
                                                style={{ height: "250px", background: "rgb(240, 240, 240)" }}

                                            >
                                                <div className=""
                                                    style={{ background: "rgb(240, 240, 240)", margin: "0 10px" }}
                                                >
                                                </div>
                                            </div>
                                        </div>
                                        {/* <ComponentDDD item={item} index={index} /> */}
                                    </div>
                                ))}
                            </OwlCarousel>
                            {/* <button onClick={goToNext} className="next-btn">Next</button> */}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ShimmerEffect