import React, { useCallback, useEffect, useState } from "react";
import Innerheader from "../../components/Innerheader";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Sidebar from "../../components/Sidebar";
import { deleteTemplate, getSearchTemplate } from "../../api/apiHandler";
import TemplateDetailsModal from "../../components/TemplateDetailsModal";
import ShareModal from "../../components/ShareModal";
import { getUserDataFromLocal } from "../../Common/LocalStorageService";
import Swal from "sweetalert2";
import { showPromiseNotification } from "../../Common/notification";
import { useFilter } from "../../Common/FilterContex";
import VideoPlayAutoDesign from "../../components/VideoPlayAutoDesign";

const TemplateSearch = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const user = getUserDataFromLocal();
    const [shareId, setShareId] = useState();
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const { prevFilters, setPrevFilters } = useFilter();
    const [invitedTemplate, setInvitedTemplate] = useState([]);
    console.log('invitedTemplate: ', invitedTemplate);
    const [templateType, setTemplateType] = useState();

    const [isTemplateDetailsModalOpen, setIsTemplateDetailsModalOpen] =
        useState(false);
    const [templateData, setTemplateData] = useState({});
    const editTemplate = useCallback(
        (data) => {
            navigate(`/edit-template/${data?.id}`);
        },
        [navigate]
    );
    const HandleDeleteTemplate = useCallback(
        (templateId) => {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-outline-success',
                    cancelButton: 'btn btn-outline-danger mx-2',
                },
                buttonsStyling: false,
            })
            swalWithBootstrapButtons
                .fire({
                    title: i18n.t("areYouSure"),
                    text: i18n.t("deleteTemplateMsg"),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: i18n.t("deleteIt"),
                    cancelButtonText: i18n.t("noCancel"),
                    reverseButtons: true,
                })
                .then(async (result) => {
                    if (result.isConfirmed) {
                        const myPromise = new Promise(async (resolve, reject) => {
                            deleteTemplate({ template_id: templateId }).then((response) => {
                                if (response.code == 200) {
                                    const filteredData = invitedTemplate.filter((item) => item.id !== templateId)
                                    setInvitedTemplate(filteredData)
                                    resolve(response);
                                } else {
                                    reject(response);
                                }
                            });
                        })
                        showPromiseNotification(myPromise);
                    }
                })
        },
        [i18n, invitedTemplate]
    );
    const userinfo = useCallback(
        (id) => {
            navigate(`/designer-profile/${id}`);
        },
        [navigate]
    );
    useEffect(() => {
        getSearchTemplate(prevFilters).then((response) => {
            console.log('response: ', response);
            if (response.code === 200) {
                setInvitedTemplate(response.data);
            } else {
                setInvitedTemplate([])
            }
        });
        window.scrollTo(0, 0);
    }, [prevFilters]);

    return (
        <main>
            <Innerheader />
            <div className="wrapper">
                <Sidebar />
                {isTemplateDetailsModalOpen && (
                    <TemplateDetailsModal
                        data={templateData || {}}
                        setTemplateData={setTemplateData}
                        isTemplateDetailsModalOpen={isTemplateDetailsModalOpen}
                        setIsTemplateDetailsModalOpen={setIsTemplateDetailsModalOpen}
                        setIsShareModalOpen={setIsShareModalOpen}
                        setShareId={setShareId}
                    />
                )}

                {/* {isShareModalOpen && (
          <ShareModal
            shareId={shareId}
            isShareModalOpen={isShareModalOpen}
            setIsShareModalOpen={setIsShareModalOpen}
          />
        )} */}

                <div className="content flex-grow-1">
                    <section className="category_main_title">
                        <div className="container-fluid">
                            <div className="row">
                                {/* <div className="col-12 mb-4 d-flex justify-content-between align-items-center flex-wrap">
                                    <h2 className="section_title">{t("templates")}</h2>
                                </div> */}
                                <div className="col-12">
                                    <div className="row">
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                                            <div class="ul_list">
                                                {invitedTemplate.length ? (
                                                    invitedTemplate?.map((item) => {
                                                        return (
                                                            <li>
                                                                {/* <div className="overlay_hover">
                                                                    <a className="template_image ">
                                                                        {item?.is_video == 1 && item?.video_url ? (
                                                                            <video
                                                                                src={item?.video_url}
                                                                                autoPlay
                                                                                loop
                                                                                muted
                                                                                style={{ objectFit: 'contain', height: '100%', width: 'auto' }}
                                                                            ></video>
                                                                        ) : (
                                                                            <img src={item?.thumbnail} alt="" />
                                                                        )}
                                                                    </a>
                                                                    <div className="overlay_div">
                                                                        <div className={`d-flex ${item?.is_video == 1 ? "justify-content-between" : "justify-content-end"}`}>
                                                                            {item?.is_video == 1 && (
                                                                                <a >
                                                                                    <img
                                                                                        src={process.env.PUBLIC_URL + "/assets/images/icon/video-pause.svg"}
                                                                                    />
                                                                                </a>
                                                                            )}
                                                                            <div className="top_li">
                                                                                <a
                                                                                    onClick={() => {
                                                                                        setShareId(item?.id);
                                                                                        setIsShareModalOpen(true);
                                                                                    }}
                                                                                >
                                                                                    <img src="assets/images/share.svg" />
                                                                                </a>
                                                                                <a
                                                                                    onClick={() => {
                                                                                        editTemplate(item);
                                                                                    }}
                                                                                >
                                                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/edit.svg`} alt="" />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                        <div className="bottom_li">
                                                                            <div
                                                                                className="user_name cursor-pointer"
                                                                                onClick={() => { setPrevFilters({}); userinfo(item?.user_id) }}
                                                                            >
                                                                                <h6 className="user-name-h6">{item?.full_name}</h6>
                                                                            </div>
                                                                            <a
                                                                                onClick={() => {
                                                                                    setTemplateData(item);
                                                                                    setIsTemplateDetailsModalOpen(true);
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={
                                                                                        process.env.PUBLIC_URL +
                                                                                        "/assets/images/info.svg"
                                                                                    }
                                                                                    className="infoImg"
                                                                                />
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                <VideoPlayAutoDesign item={item} editTemplate={editTemplate} setShareId={setShareId} setIsShareModalOpen={setIsShareModalOpen} setIsTemplateDetailsModalOpen={setIsTemplateDetailsModalOpen} userinfo={userinfo} setTemplateData={setTemplateData} setPrevFilters={setPrevFilters} />
                                                            </li>
                                                        );
                                                    })
                                                ) : (
                                                    <div
                                                        style={{ height: "100px" }}
                                                        className="fw-light d-flex justify-content-center align-items-center  w-100"
                                                    >
                                                        {t("noDataFound")}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            {isShareModalOpen && (
                <ShareModal
                    isShareModalOpen={isShareModalOpen}
                    shareId={shareId}
                    setIsShareModalOpen={setIsShareModalOpen}
                />
            )}
        </main>
    );
};

export default TemplateSearch;
