import React from "react";
import { useTranslation } from "react-i18next";

const TestimonialModal = (props) => {
  const { isTestimonialOpen, setIsTestimonialOpen } = props;
  const item = isTestimonialOpen?.data;
  const { t, i18n } = useTranslation();

  return (
    <>
      <div
        className={`modal ${
          isTestimonialOpen.isOpen ? "fade show d-block " : "d-none"
        }`}
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="custom_btn"
                // data-bs-dismiss="modal"
                // aria-label="Close"
                onClick={() => {
                  setIsTestimonialOpen({ isOpen: false, data: {} });
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/close.svg"}
                />
              </button>
              <div className="title-text text-center">
                <h6
                  dangerouslySetInnerHTML={{ __html: t("ourTestimonials") }}
                ></h6>
              </div>
              <div className="testimonial_main h-100">
                <div style={{ maxHeight: "400px", overflow: "auto" }}>
                  <p>{item?.feedback}</p>
                </div>
                <div className="testimonial_rate">
                  <div className="rating">
                    {Array(item?.rating)
                      .fill(null)
                      ?.map((item) => {
                        return (
                          <span>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/rating/star.svg"
                              }
                            />
                          </span>
                        );
                      })}
                    {5 - item?.rating > 0 &&
                      Array(5 - item?.rating)
                        .fill(null)
                        ?.map((item) => {
                          return (
                            <span>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/rating/start_outline.svg"
                                }
                              />
                            </span>
                          );
                        })}
                  </div>
                </div>
                <div className="testimonial_profile">
                  <div className="user_profile">
                    <img src={item?.profile_image} alt="client" />
                  </div>
                  <h5>{item?.full_name}</h5>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default TestimonialModal;
