import React, { useEffect, useState } from "react";
import Innerheader from "../../components/Innerheader";
import Sidebar from "../../components/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import Designerpost from "./components/Designerpost";
import Designerreels from "./components/Designerreels";
import { useTranslation } from "react-i18next";
import {
  getAllCategories,
  getAllTemplate,
  getDesignerDetails,
  getUserTemplate,
} from "../../api/apiHandler";
import Instagramreels from "../home/components/Instagramreels1";
import { getToken } from "../../Common/LocalStorageService";
import Header from "../../components/Header";
import AllTemplateList from "../home/components/AllTemplateList";

const Designer_profile = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [designerDetails, setDesignerDetails] = useState({});
  const [category, setCategory] = useState([]);
  const [activeCategory, setActiveCategory] = useState(1);
  const [templateData, setTemplateData] = useState([]);
  console.log('templateData: ', templateData);
  const [isLogin, setIsLogin] = useState(false);


  useEffect(() => {
    getAllCategories({}).then((response) => {

      if (response.code === 200) {
        setCategory(response?.data);
      } else {
        setCategory([]);
      }
    });
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (activeCategory) {
      getUserTemplate({ category_id: activeCategory, userId: id }).then(
        (_template) => {
          console.log('_template: ', _template);
          if (_template.code === 200) {
            setTemplateData(_template?.data);
          }
        }
      );
    }
  }, [activeCategory, id]);
  useEffect(() => {
    getDesignerDetails({ userId: id }).then((response) => {
      if (response.code == 200) {

        setDesignerDetails(response?.data);
      }
    });
  }, [id]);

  return (
    <>
      <main>
        {getToken() ? (
          <Innerheader />
        ) : (
          <Header openLogin={isLogin} setOpenLogin={setIsLogin} />
        )}
        <div className={getToken() ? "wrapper" : ""}>
          {getToken() && <Sidebar />}
          <div
            className={`content flex-grow-1 ${getToken() ? "" : "container"} `}
          >
            {/* {getToken() && ( */}
            <section className="category_main_title">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="login_header">
                      <a onClick={() => navigate(-1)}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/left_arrow.svg"
                          }
                        />{" "}
                        {t("back")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* )} */}
            <section className="category_main_title">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 mb-3 d-flex flex-wrap justify-content-between align-items-center">
                    {/* height change in blow class  */}
                    <div className="user_cover" >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/profile_cover.svg"
                        }
                        style={{ visibility: 'hidden' }}
                      />
                      <div className="userd_profile">
                        <img src={designerDetails?.profile_image} />
                      </div>
                      <div className="">
                        <div className="div_rpfile">
                          <h2> {designerDetails?.full_name}</h2>
                          <p>{designerDetails?.about}</p>
                          <div className="profile_img">
                            <img style={{ width: '20px', height: "20px" }}
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/usaer_category.svg"
                              }
                            />
                            <p>
                              {templateData?.count} {t("templatesTitle")}
                            </p>
                          </div>
                        </div>
                        {/* <div className="profile_p">
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <section className="category_main_title">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 mb-3 d-flex flex-wrap justify-content-between align-items-center">
                    <h2 className="section_title">{t("topCategories")}</h2>
                  </div>
                  <div className="col-12 mb-3">
                    <div className="category_list">
                      <ul>
                        {category?.map((item) => {
                          return (
                            <li
                              key={`category_${item?.id}`}
                              className={
                                activeCategory == item?.id ? "active" : ""
                              }
                              onClick={() => {
                                setActiveCategory(item?.id);
                              }}
                            >
                              <a>
                                <div className="category_inner_div">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/category_icon.svg"
                                    }
                                  />{" "}
                                  <span> {item?.name}</span>
                                </div>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}

            {/* <Instagrampost /> */}
            <div className="PadTop100px" >
              {/* {templateData && templateData?.data && templateData?.data.length > 0 && templateData?.data?.map((item) => { */}
              {/* return ( */}
              <AllTemplateList
                data={templateData.data}
                isDesigner={true}
                setIsLogin={setIsLogin}
              />
              {/* ); */}
              {/* })} */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Designer_profile;
