import React from "react";
import { observer } from "mobx-react-lite";
import { Star } from "react-konva";

import { NumericInput, Navbar, Alignment } from "@blueprintjs/core";
import ColorPicker from "polotno/toolbar/color-picker";

import { unstable_registerShapeComponent } from "polotno/config";
import { unstable_registerToolbarComponent } from "polotno/config";
import { unstable_registerShapeModel } from "polotno/config";
import { unstable_registerTransformerAttrs } from "polotno/config";

unstable_registerShapeModel(
  {
    type: "star",
    radius: 100,
    fill: "black",
    numPoints: 6,
  },

  (starModel) => {
    return starModel.actions((self) => {
      return {
        setNumPoints(numPoints) {
          self.numPoints = numPoints;
        },
      };
    });
  }
);

export const StarElement = observer(({ element, store }) => {
  const ref = React.useRef(null);

  const handleChange = (e) => {
    const node = e.currentTarget;
    const scaleX = node.scaleX();

    node.scaleX(1);
    node.scaleY(1);

    element.set({
      x: node.x(),
      y: node.y(),
      rotation: e.target.rotation(),
      radius: element.radius * scaleX,
    });
  };

  return (
    <Star
      ref={ref}
      name="element"
      id={element.id}
      x={element.x}
      y={element.y}
      fill={element.fill}
      offsetX={-element.radius}
      offsetY={-element.radius}
      rotation={element.rotation}
      opacity={element.opacity}
      draggable={!element.locked}
      outerRadius={element.radius}
      innerRadius={element.radius * 0.5}
      onDragMove={handleChange}
      onTransform={handleChange}
    />
  );
});

unstable_registerShapeComponent("star", StarElement);

unstable_registerTransformerAttrs("star", {
  enabledAnchors: ["top-left", "top-right", "bottom-left", "bottom-right"],
});

const LineToolbar = observer(({ store }) => {
  const element = store.selectedElements[0];

  return (
    <Navbar.Group align={Alignment.LEFT}>
      <ColorPicker
        value={element.fill}
        onChange={(fill) =>
          element.set({
            fill,
          })
        }
        store={store}
      />
      <NumericInput
        onValueChange={(val) => {
          element.set({ radius: val });
        }}
        value={element.radius}
        style={{ width: "50px", marginLeft: "10px" }}
        min={1}
        max={500}
      />
    </Navbar.Group>
  );
});

unstable_registerToolbarComponent("star", LineToolbar);
