import React, { useState } from "react";
import Innerheader from "../../components/Innerheader";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PhoneInput } from "react-international-phone";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import checkValidation from "../../validation/ValidationSchema";
import Sidebar from "../../components/Sidebar";
import { PhoneNumberUtil } from "google-libphonenumber";
import {
  getUserDataFromLocal,
  saveUserDataToLocal,
} from "../../Common/LocalStorageService";
import moment from "moment/moment";
import {
  showErrorMessage,
  showPromiseNotification,
} from "../../Common/notification";
import { editProfile } from "../../api/apiHandler";
import uploadToS3, { generateRandomFileName } from "../../Common/s3AwsService";
import imageCompression from 'browser-image-compression';

const Profile = () => {
  const navigate = useNavigate();
  const [isImgChange, setIsImgChange] = useState(false);
  const [isLoading, setIsLoading] = useState(0);
  const [profileImage, setProfileImage] = useState({});
  console.log("profileImage: ", profileImage);
  const schema = checkValidation(["Fullname", "Email", "DateOfBirth"]);

  const userData = getUserDataFromLocal();

  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    watch,
    formState: { errors, isSubmitted },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      profile_image: userData?.profile_image,
      Fullname: userData?.full_name,
      Email: userData?.email,
      PhoneNumber: userData?.country_code + userData?.mobile_no,
      DateOfBirth: moment(userData?.dob).format("YYYY-MM-DD"),
    },
  });

  const { t, i18n } = useTranslation();

  const onSubmit = async (data) => {
    console.log("data?.PhoneNumber: ", data?.PhoneNumber);
    const phoneNumber = data?.PhoneNumber
      ? phoneUtil.parseAndKeepRawInput(data?.PhoneNumber)
      : null;

    const payload = {
      full_name: data?.Fullname,
      email: data?.Email,
      dob: data?.DateOfBirth,
      country_code: phoneNumber && `+${phoneNumber.getCountryCode()}`,
      phone: phoneNumber && phoneNumber.getNationalNumber().toString(),
    };
    if (isImgChange) {
      const compressedImage = await imageCompression(profileImage, {
        maxSizeMB: 0.3,
        maxWidthOrHeight: 600,
        useWebWorker: true,
        initialQuality: 0.3
      });
      await uploadToS3(
        compressedImage,
        `${generateRandomFileName()}.${compressedImage.type.split("/")[1]}`,
        "user"
      ).then((response) => {
        payload.profile_image = response.key;
      });
    }
    const myPromise = new Promise((resolve, reject) =>
      editProfile(payload).then((response) => {
        if (response.code == 200) {
          saveUserDataToLocal(response.data);
          setIsLoading(isLoading + 1)
          resolve(response);
        } else {
          reject(response);
        }
      })
    );
    showPromiseNotification(myPromise);

    // navigate("/home");
  };

  const phoneUtil = PhoneNumberUtil.getInstance();
  const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  const handleFileImageChange = (event) => {
    console.log("event: ", event);
    const selectedFiles = event.target.files;
    if (selectedFiles.length > 0) {
      const file = selectedFiles[0];
      if (file.type.startsWith("image/")) {
        const imageUrl = URL.createObjectURL(file);
        setValue("profile_image", imageUrl);
        setIsImgChange(true);
        setProfileImage(file);
      } else {
        showErrorMessage("Only image is allow");
      }
    }
  };

  return (
    <main>
      <Innerheader isLoading={isLoading} setIsLoading={setIsLoading} />
      <div className="wrapper">
        <Sidebar />
        <div className="content flex-grow-1">
          <section className="category_main_title">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 mb-4 ">
                  <div className="main_gray">
                    <div className="row">
                      <div className="col-12">
                        <div className="login_header">
                          <a
                            className="cursor-pointer"
                            onClick={() => navigate("/home")}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/left_arrow.svg"
                              }
                            />{" "}
                            {t("back")}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row align-items-center justify-content-evenly">
                      <div className="col-lg-2 col-md-3 col-sm-6 col-12 mb-3 ">
                        <div className="edit_profile">
                          <img src={watch("profile_image")} />
                          <img
                            src="assets/images/camera.svg"
                            className="camera_icon"
                          />
                          <input type="file" accept="image/png, image/jpeg, image/jpg" onChange={handleFileImageChange} />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
                        <div className="title-text">
                          <h5 className="mb-2 fw-bold text-center">
                            {t("editProfile")}
                          </h5>

                          <p className="mx-auto text-center">
                            {t("editPersonalDetails")}
                          </p>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="login_form">
                              <div className="row">
                                <div className="col-12 mb-3">
                                  <div className="form-group left_fixed">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={t("fullName")}
                                      {...register("Fullname")}
                                    />
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/account.svg"
                                      }
                                    />
                                  </div>
                                  {errors.Fullname && (
                                    <span className="text-danger">
                                      {" "}
                                      {errors.Fullname.message}
                                    </span>
                                  )}
                                </div>
                                <div className="col-12 mb-3">
                                  <div className="form-group left_fixed">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={t("email")}
                                      {...register("Email")}
                                    />
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/email.svg"
                                      }
                                    />
                                  </div>
                                  {errors.Email && (
                                    <span className="text-danger">
                                      {" "}
                                      {errors.Email.message}
                                    </span>
                                  )}
                                </div>
                                <div className="col-12 mb-3">
                                  <PhoneInput
                                    defaultCountry="ua"
                                    value={watch("PhoneNumber")}
                                    className="form-group"
                                    inputProps={{
                                      className: "form-control",
                                      name: "PhoneNumber",
                                    }}
                                    inputStyle={{
                                      borderTopLeftRadius: "0",
                                      borderBottomLeftRadius: "0",
                                      borderLeft: "0",
                                      paddingLeft: "0",
                                    }}
                                    countrySelectorStyleProps={{
                                      buttonContentWrapperClassName:
                                        "form-control h-100",
                                      buttonContentWrapperStyle: {
                                        borderTopRightRadius: "0",
                                        borderBottomRightRadius: "0",
                                        border: "1px solid #E4EAF7",
                                        paddingRight: "5px",
                                        borderRight: "0",
                                      },

                                      buttonClassName: "border-0 h-100",
                                    }}
                                    // dialCodePreviewStyleProps={{className:"form-control"}}
                                    onChange={(phone) => {
                                      const errorPhone = isPhoneValid(phone);

                                      if (!errorPhone) {
                                        // Set the error if the phone number is invalid
                                        setError("PhoneNumber", {
                                          type: "phoneInvalid",
                                          message: i18n.t(`phoneInvalid`),
                                        });
                                      } else {
                                        // Clear any existing phone number error if the number is valid
                                        setValue("PhoneNumber", phone);
                                        clearErrors("PhoneNumber");
                                      }
                                    }}
                                  />
                                  {console.log("errors: ", errors)}
                                  {isSubmitted && errors.PhoneNumber && (
                                    <span className="text-danger">
                                      {" "}
                                      {errors.PhoneNumber.message}
                                    </span>
                                  )}
                                </div>
                                <div className="col-12 mb-3">
                                  <div className="form-group left_fixed">
                                    <input
                                      type={"date"}
                                      className="form-control"
                                      placeholder={t("dateOfBirth")}
                                      {...register("DateOfBirth")}
                                    />
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/cake.svg"
                                      }
                                    />
                                  </div>
                                  {errors.DateOfBirth && (
                                    <span className="text-danger">
                                      {" "}
                                      {errors.DateOfBirth.message}
                                    </span>
                                  )}
                                </div>

                                <div className="col-12 mt-5">
                                  <div className="form-group left_fixed">
                                    <button
                                      type="submit"
                                      className="btn btn-theme"
                                    >
                                      {t("update")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
};

export default Profile;
