import "./App.css";
import {
  BrowserRouter,
  useRoutes,
  Route,
  useNavigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import Signup from "./pages/auth/Signup/Signup";
import Login from "./pages/auth/Login/Login";
import Aboutus from "./pages/cms/Aboutus";
import Contactus from "./pages/cms/Contactus";
import Footer from "./components/Footer";
import Faqs from "./pages/cms/Faqs";
import Terms_conditions from "./pages/cms/Terms_conditions";
import Privacy_policy from "./pages/cms/Privacy_policy";
import Profile from "./pages/auth/Profile";
// import Invited_project from "./pages/user/Invited_project";
import Invited_project from "./pages/user/Invited_project_2";
// import MyProjects from "./pages/my-projects";
import MyProjects from "./pages/my-projects/index2";
// import Home from "./pages/home/index";
import Home from "./pages/home/index1";
import Index from "./pages/landing_page/Index";
import Subscription from "./pages/Subscription/Subscription";
import Change_password from "./pages/auth/Change_password";
import My_team from "./pages/Myteam/My_team";
import { useEffect, useState } from "react";
import My_earning from "./pages/user/My_earning";
// import Designer_profile from "./pages/designer/Designer_profile";
import Designer_profile from "./pages/designer/Designer_profile1";
import Invite_member from "./pages/user/Invite_member";
import { getToken } from "./Common/LocalStorageService";
import { history } from "./Common/helpers";
// import TempleteList from "./pages/home/components/TempleteListing";
import TempleteList from "./pages/home/components/TempleteListing1";
import "magnific-popup/dist/magnific-popup.css";
import Editor from "./pages/Editor";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import { FilterProvider } from "./Common/FilterContex";
import BecomeDesigner from "./components/BecomeDesigner";
import TemplateSearch from "./pages/my-projects/TemplateSearch";
import MyAccount from "./pages/auth/My_account";

const PublicLayout = ({ children }) => {
  const navigate = useNavigate();
  const [isRender, setIsRender] = useState(false);
  useEffect(() => {
    const isLoggedIn = getToken();
    if (isLoggedIn) {
      navigate("/home");
    } else {
      // navigate("/")
      setIsRender(true);
    }
  }, [navigate]);
  if (!isRender) {
    return null;
  }
  return <Outlet />;
};

const PrivateLayout = ({ children }) => {
  const navigate = useNavigate();
  const [isRender, setIsRender] = useState(false);
  const location = useLocation();

  // Get the current URL path
  const currentPath = location.pathname;
  console.log('currentPath: ', currentPath);
  useEffect(() => {
    const isNotLoggedIn = !getToken();
    if (isNotLoggedIn) {
      console.log('window.location.pathname: ', window.location.pathname);
      navigate("/", { state: { is_share: window.location.pathname.endsWith("/c2hhcmU="), path: window.location.pathname } });
    } else {
      setIsRender(true);
    }
  }, [currentPath, navigate]);
  if (!isRender) {
    return null;
  }
  return <Outlet />;
};

const routeConfig = [
  { path: "/", element: <Index /> },
  { path: "/home", element: <Home /> },
  { path: "/designer-profile/:id", element: <Designer_profile /> },
  { path: "/template/category/:id", element: <TempleteList /> },
  {
    path: "/designer/:userId/template/category/:id",
    element: <TempleteList />,
  },
  {
    path: "/template/similar/:id",
    element: <TempleteList isSimilarTemplate={true} />,
  },
  {
    path: "/",
    element: <PublicLayout />,
    children: [
      // { path: "signup", element: <Signup /> },
      // { path: "login", element: <Login /> },
      // { path: "", element: <Index /> },
    ],
  },
  {
    path: "/",
    element: <PrivateLayout />,
    children: [
      { path: "/profile", element: <Profile /> },
      { path: "/my-account", element: <MyAccount /> },
      { path: "/invited-project", element: <Invited_project /> },
      { path: "/my-projects", element: <MyProjects /> },
      // { path: "/home/:categoryId", element: <Home /> },
      { path: "/subscription", element: <Subscription /> },
      { path: "/change-password", element: <Change_password /> },
      { path: "/my-team", element: <My_team /> },
      { path: "/my-earning", element: <My_earning /> },

      { path: "/invite-member", element: <Invite_member /> },
      { path: "/become-designer", element: <BecomeDesigner /> },
      { path: "/search-template", element: <TemplateSearch /> },
      { path: "/add-template", element: <Editor type="add" /> },
      { path: "/edit-template/:id", element: <Editor type="edit" /> },
      { path: "/copy-template/:id", element: <Editor type="copy" /> },
      { path: "/copy-template/:id/:share", element: <Editor type="copy" /> },
    ],
  },

  { path: "about-us", element: <Aboutus /> },
  { path: "contact-us", element: <Contactus /> },
  { path: "faqs", element: <Faqs /> },
  { path: "terms-conditions", element: <Terms_conditions /> },
  { path: "privacy-policy", element: <Privacy_policy /> },
  { path: "*", element: <PageNotFound /> },
];

function App() {
  const routeResult = useRoutes(routeConfig);
  history.navigate = useNavigate();
  history.location = useLocation();
  return (
    <div className="App h-100">
      <FilterProvider>{routeResult}</FilterProvider>
    </div>
  );
}

export default App;
