import React, { useCallback, useEffect, useState } from "react";
import { InputGroup } from "@blueprintjs/core";
import styled from "polotno/utils/styled";
import { ImagesGrid } from "polotno/side-panel";
import { t } from "polotno/utils/l10n";
import { getCliparts, getShapes, getSocialMediaIcon, getStickers } from "../../../api/apiHandler";
import { getImageSize } from "polotno/utils/image";
import i18n from "../../../i18n";

async function imageUrlToBase64(imageUrl) {
  try {
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }

    const contentType = response.headers.get("content-type");
    let base64;

    if (contentType.includes("image/svg+xml")) {
      const text = await response.text();
      base64 = btoa(text);
    } else {
      const arrayBuffer = await response.arrayBuffer();
      base64 = arrayBufferToBase64(arrayBuffer);
    }

    const base64String = `data:${contentType};base64,${base64}`;
    console.log(base64String);
    return base64String;
  } catch (error) {
    console.error("There has been a problem with your fetch operation:", error);
  }
}

// Helper function to convert ArrayBuffer to base64
function arrayBufferToBase64(buffer) {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

const NounContainer = styled("div")`
  height: 100%;
  overflow: hidden;
`;

export const NounprojectPanel = ({ store, query }) => {
  const [socialMediaIconData, setSocialMediaIconData] = useState([]);
  const [type, setType] = useState("");
  console.log('type: ', type);
  const [stickerData, setStickerData] = useState([]);
  const [clipartData, setClipartData] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [hasMoreSticker, setHasMoreSticker] = useState(false);
  const [hasMoreClipart, setHasMoreClipart] = useState(false);
  // const [shapeData, setShapeData] = useState([]);
  // const [hasMoreShape, setHasMoreShape] = useState(false);
  // const [isLoadingShape, setIsLoadingShape] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingClipart, setIsLoadingClipart] = useState(false);
  const [isLoadingSticker, setIsLoadingSticker] = useState(false);

  useEffect(() => {
    // setIsLoading(true);
    getSocialMediaIcon({ page: 1, limit: 10 }).then((response) => {
      if (response.code == 200) {
        setSocialMediaIconData(response.data.data);
        setHasMore(true);
        setIsLoading(false);
      } else {
        setHasMore(false);
        setIsLoading(false);
      }
    });
    getStickers({ page: 1, limit: 10 }).then((response) => {
      if (response.code == 200) {
        setStickerData(response.data.data);
        setHasMoreSticker(true);
        setIsLoadingSticker(false);
      } else {
        setHasMoreSticker(false);
        setIsLoadingSticker(false);
      }
    });
    getCliparts({ page: 1, limit: 10 }).then((response) => {
      if (response.code == 200) {
        setClipartData(response.data.data);
        setHasMoreClipart(true);
        setIsLoadingClipart(false);
      } else {
        setHasMoreClipart(false);
        setIsLoadingClipart(false);
      }
    });
    // getShapes({ page: 1, limit: 10 }).then((response) => {
    //   if (response.code == 200) {
    //     setShapeData(response.data.data);
    //     setHasMoreShape(true);
    //     setIsLoadingShape(false);
    //   } else {
    //     setHasMoreShape(false);
    //     setIsLoadingShape(false);
    //   }
    // });
  }, []);
  const loadMore = useCallback(() => {
    // setIsLoading(true);
    getSocialMediaIcon({ page: page + 1, limit: 10 }).then((response) => {
      if (response.code == 200) {
        setSocialMediaIconData((prev) => [...prev, ...response.data.data]);
        setHasMore(true);
        setIsLoading(false);
      } else {
        setHasMore(false);
        setIsLoading(false);
      }
    });
    getStickers({ page: page + 1, limit: 10 }).then((response) => {
      if (response.code == 200) {
        setStickerData((prev) => [...prev, ...response.data.data]);
        setHasMoreSticker(true);
        setIsLoadingSticker(false);
      } else {
        setHasMoreSticker(false);
        setIsLoadingSticker(false);
      }
    });
    getCliparts({ page: page + 1, limit: 10 }).then((response) => {
      if (response.code == 200) {
        setClipartData((prev) => [...prev, ...response.data.data]);
        setHasMoreClipart(true);
        setIsLoadingClipart(false);
      } else {
        setHasMoreClipart(false);
        setIsLoadingClipart(false);
      }
    });
    // getShapes({ page: page + 1, limit: 10 }).then((response) => {
    //   if (response.code == 200) {
    //     setShapeData((prev) => [...prev, ...response.data.data]);
    //     setHasMoreShape(true);
    //     setIsLoadingShape(false);
    //   } else {
    //     setHasMoreShape(false);
    //     setIsLoadingShape(false);
    //   }
    // });
    setPage(page + 1);
  }, [page]);

  return (
    <>
      <div style={{ overflow: 'scroll' }}>
        {type != "" && (
          <div className="LeftArrowBtn" style={{ cursor: "pointer" }}>
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/images/left_arrow.svg"
              }
              style={{ height: '18px' }}
              alt="logo"
              className="img-contain"
              onClick={() => { setType("") }}
            />
            <p onClick={() => { setType("") }} >

              {i18n.t("back")}
            </p>
          </div>
        )}
        {(type == "" || type == "social") && (
          <div className="BgOfTheSocial" >
            <div className="BgOfTheSocialOne">
              <p>{i18n.t("socialMediaIcons")}</p>
              {
                type == "" && (
                  <p onClick={() => { setType("social") }} style={{ cursor: "pointer" }}>{i18n.t("seeMore")}</p>
                )
              }
            </div>
            <div style={{ height: type != "" && "81vh" }}>
              <NounContainer>
                <ImagesGrid
                  shadowEnabled={false}
                  images={type == "" ? socialMediaIconData.slice(0, 5) : socialMediaIconData}
                  getPreview={(item) => item.image}
                  isLoading={isLoading}
                  itemHeight={"80px"}
                  onSelect={async (image, pos, element) => {
                    // const url = await imageUrlToBase64(image.image);
                    const url = await image.image;
                    console.log("url: ", url);
                    let { width, height } = await getImageSize(url);
                    const isSVG = url.indexOf("svg+xml") >= 0 || url.indexOf(".svg") >= 0;
                    console.log("isSVG: ", isSVG);

                    const type = isSVG ? "svg" : "image";

                    if (
                      element &&
                      element.type === "svg" &&
                      !element.locked &&
                      type === "image"
                    ) {
                      element.set({ maskSrc: url });
                      return;
                    }

                    if (
                      element &&
                      element.type === "image" &&
                      !element.locked &&
                      type === "svg"
                    ) {
                      element.set({ clipSrc: url });
                      return;
                    }

                    const scale = Math.min(store.width / width, store.height / height, 1);
                    width = width * scale;
                    height = height * scale;

                    const x = (pos?.x || store.width / 2) - width / 2;
                    const y = (pos?.y || store.height / 2) - height / 2;

                    store.activePage?.addElement({
                      type,
                      src: url,
                      x,
                      y,
                      width,
                      height,
                    });
                  }}
                  rowsNumber={type == "" ? 5 : 4}
                  loadMore={hasMore && loadMore}
                />
              </NounContainer>
            </div>
          </div>
        )}
        {(type == "" || type == "sticker") && (
          <div className="BgOfTheSocial" >
            <div className="BgOfTheSocialOne">
              <p>{i18n.t("stickers")}</p>
              {
                type == "" && (
                  <p onClick={() => { setType("sticker") }} style={{ cursor: "pointer" }}>{i18n.t("seeMore")}</p>
                )
              }
            </div>
            <div style={{ height: type != "" && "81vh" }}>
              <NounContainer>
                <ImagesGrid
                  shadowEnabled={false}
                  images={type == "" ? stickerData.slice(0, 5) : stickerData}
                  getPreview={(item) => item.image}
                  isLoading={isLoadingSticker}
                  itemHeight={"80px"}
                  onSelect={async (image, pos, element) => {
                    // const url = await imageUrlToBase64(image.image);
                    const url = await image.image;
                    console.log("url: ", url);
                    let { width, height } = await getImageSize(url);
                    const isSVG = url.indexOf("svg+xml") >= 0 || url.indexOf(".svg") >= 0;
                    console.log("isSVG: ", isSVG);

                    const type = isSVG ? "svg" : "image";

                    if (
                      element &&
                      element.type === "svg" &&
                      !element.locked &&
                      type === "image"
                    ) {
                      element.set({ maskSrc: url });
                      return;
                    }

                    if (
                      element &&
                      element.type === "image" &&
                      !element.locked &&
                      type === "svg"
                    ) {
                      element.set({ clipSrc: url });
                      return;
                    }

                    const scale = Math.min(store.width / width, store.height / height, 1);
                    width = width * scale;
                    height = height * scale;

                    const x = (pos?.x || store.width / 2) - width / 2;
                    const y = (pos?.y || store.height / 2) - height / 2;

                    store.activePage?.addElement({
                      type,
                      src: url,
                      x,
                      y,
                      width,
                      height,
                    });
                  }}
                  rowsNumber={type == "" ? 5 : 4}
                  loadMore={hasMoreSticker && loadMore}
                />
              </NounContainer>
            </div>
          </div>
        )}
        {(type == "" || type == "clipart") && (
          <div className="BgOfTheSocial" >
            <div className="BgOfTheSocialOne">
              <p>{i18n.t("cliparts")}</p>
              {
                type == "" && (
                  <p onClick={() => { setType("clipart") }} style={{ cursor: "pointer" }}>{i18n.t("seeMore")}</p>
                )
              }
            </div>
            <div style={{ height: type != "" && "81vh" }}>
              <NounContainer>
                <ImagesGrid
                  shadowEnabled={false}
                  images={type == "" ? clipartData.slice(0, 5) : clipartData}
                  getPreview={(item) => item.image}
                  isLoading={isLoadingClipart}
                  itemHeight={"80px"}
                  onSelect={async (image, pos, element) => {
                    // const url = await imageUrlToBase64(image.image);
                    const url = await image.image;
                    console.log("url: ", url);
                    let { width, height } = await getImageSize(url);
                    const isSVG = url.indexOf("svg+xml") >= 0 || url.indexOf(".svg") >= 0;
                    console.log("isSVG: ", isSVG);

                    const type = isSVG ? "svg" : "image";

                    if (
                      element &&
                      element.type === "svg" &&
                      !element.locked &&
                      type === "image"
                    ) {
                      element.set({ maskSrc: url });
                      return;
                    }

                    if (
                      element &&
                      element.type === "image" &&
                      !element.locked &&
                      type === "svg"
                    ) {
                      element.set({ clipSrc: url });
                      return;
                    }

                    const scale = Math.min(store.width / width, store.height / height, 1);
                    width = width * scale;
                    height = height * scale;

                    const x = (pos?.x || store.width / 2) - width / 2;
                    const y = (pos?.y || store.height / 2) - height / 2;

                    store.activePage?.addElement({
                      type,
                      src: url,
                      x,
                      y,
                      width,
                      height,
                    });
                  }}
                  rowsNumber={type == "" ? 5 : 4}
                  loadMore={hasMoreClipart && loadMore}
                />
              </NounContainer>
            </div>
          </div>
        )}
        {/* {(type == "" || type == "shape") && (
          <div className="BgOfTheSocial" >
            <div className="BgOfTheSocialOne">
              <p>{i18n.t("shapesPolotno")}</p>
              {
                type == "" && (
                  <p onClick={() => { setType("shape") }} style={{ cursor: "pointer" }}>{i18n.t("seeMore")}</p>
                )
              }
            </div>
            <div style={{ height: type != "" && "81vh" }}>
              <NounContainer>
                <ImagesGrid
                  shadowEnabled={false}
                  images={type == "" ? shapeData.slice(0, 5) : shapeData}
                  getPreview={(item) => item.image}
                  isLoading={isLoadingShape}
                  itemHeight={"80px"}
                  onSelect={async (image, pos, element) => {
                    const url = await imageUrlToBase64(image.image);
                    console.log("url: ", url);
                    let { width, height } = await getImageSize(url);
                    const isSVG = url.indexOf("svg+xml") >= 0 || url.indexOf(".svg") >= 0;
                    console.log("isSVG: ", isSVG);

                    const type = isSVG ? "svg" : "image";

                    if (
                      element &&
                      element.type === "svg" &&
                      !element.locked &&
                      type === "image"
                    ) {
                      element.set({ maskSrc: url });
                      return;
                    }

                    if (
                      element &&
                      element.type === "image" &&
                      !element.locked &&
                      type === "svg"
                    ) {
                      element.set({ clipSrc: url });
                      return;
                    }

                    const scale = Math.min(store.width / width, store.height / height, 1);
                    width = width * scale;
                    height = height * scale;

                    const x = (pos?.x || store.width / 2) - width / 2;
                    const y = (pos?.y || store.height / 2) - height / 2;

                    store.activePage?.addElement({
                      type,
                      src: url,
                      x,
                      y,
                      width,
                      height,
                    });
                  }}
                  rowsNumber={type == "" ? 5 : 4}
                  loadMore={hasMoreShape && loadMore}
                />
              </NounContainer>
            </div>
          </div>
        )} */}

      </div>
    </>
  );
};

export const SocialMediaIconsPanel = ({ store }) => {
  const requestTimeout = React.useRef();
  const [query, setQuery] = React.useState("");
  const [delayedQuery, setDelayedQuery] = React.useState(query);

  React.useEffect(() => {
    requestTimeout.current = setTimeout(() => {
      setDelayedQuery(query);
    }, 500);
    return () => {
      clearTimeout(requestTimeout.current);
    };
  }, [query]);

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {/* <InputGroup
        leftIcon="search"
        placeholder={t("sidePanel.searchPlaceholder")}
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        style={{
          marginBottom: "0",
        }}
        type="search"
      /> */}
      <NounprojectPanel query={delayedQuery} store={store} />
    </div>
  );
};
