import React, { useCallback, useEffect, useState } from "react";
import Innerheader from "../../components/Innerheader";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { loadStripe } from "@stripe/stripe-js";
import Swal from "sweetalert2";
import FinalSubPayment from "./Components/CheckoutWaitingPage";
import { cancelSubscription, getSubscription } from "../../api/apiHandler";
import { useSelector } from "react-redux";
import {
  getUserDataFromLocal,
  saveUserDataToLocal,
} from "../../Common/LocalStorageService";
import { showPromiseNotification } from "../../Common/notification";
import moment from "moment/moment";
import ChangeCardDetails from "./Components/ChangeCardDetails";
import _ from 'lodash'

const Subscription = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const changelanguage = useSelector((state) => state.changelanguage);
  const [planDuration, setPlanDuration] = useState("monthly");
  const [subscriptionDetail, setSubscriptionDetail] = useState([]);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const user = getUserDataFromLocal();
  console.log('user: ', user);

  const handleswitch = (event) => {
    console.log("event: ", event.target.checked);
    if (event.target.checked) {
      setPlanDuration("yearly");
    } else {
      setPlanDuration("monthly");
    }
  };

  const getPlanDetails = useCallback(
    () =>
      getSubscription({}).then((response) => {
        if (response.code === 200) {
          setSubscriptionDetail(response.data);
        }
      }),
    []
  );

  const canUpdatePlan = (currentPlan, newPlan) => {
    console.log("currentPlan: ", currentPlan);
    const updateRules = {
      pro: {
        monthly: ["pro_yearly", "enterprise_monthly", "enterprise_yearly"],
        yearly: ["enterprise_yearly"],
      },
      enterprise: {
        monthly: ["pro_yearly", "enterprise_yearly"],
        yearly: [],
      },
    };

    if (currentPlan == "_") return true; // If no current plan, any plan can be selected

    const [currentPlanType, currentDuration] = currentPlan.split("_");
    const [newPlanType, newDuration] = newPlan.split("_");

    return updateRules[currentPlanType][currentDuration].includes(newPlan);
  };

  const handleCancelPlan = useCallback(() => {
    const myPromise = new Promise((resolve, reject) =>
      cancelSubscription({}).then((response) => {
        if (response.code === 200) {
          saveUserDataToLocal(response?.data);
          getPlanDetails();
          resolve(response);
        } else {
          reject(response);
        }
      })
    );
    showPromiseNotification(myPromise);
  }, [getPlanDetails]);

  const getButton = (item, user, planDuration) => {
    const currentPlan = `${user?.subscribed_type || ""}_${user?.subscription_duration || ""
      }`;
    console.log("currentPlan: ", currentPlan);
    const newPlan = `${item.plan}_${planDuration}`;
    console.log("newPlan: ", newPlan);

    const isUpdatable = canUpdatePlan(currentPlan, newPlan);
    console.log("isUpdatable: ", isUpdatable);

    return (
      <>
        <button
          onClick={() => {
            setSelectedPlan(item);
            setPaymentModalOpen(true);
          }}
          disabled={!isUpdatable}
          className="subs_btn_1"
          style={{
            cursor: !isUpdatable ? "not-allowed" : "pointer",
            // opacity: 0.9,
            background: !isUpdatable ? "#d9d5d5" : "",
          }}
        >
          {user?.is_subscribed &&
            user?.subscribed_type === item.plan &&
            user?.subscription_duration === planDuration
            ? user?.is_subscription_cancel
              ? t("alreadyCancelled")
              : t("alreadySubscribed")
            : t(user?.is_subscribed ? "Businesscta" : "cta")}
        </button>
        {/* {!user?.is_subscription_cancel &&
          user?.is_subscribed &&
          user?.subscribed_type === item.plan &&
          user?.subscription_duration === planDuration ? (
          <div className="text-center">
            <button
              className="text-decoration-underline text-white mt-2 text-center"
              onClick={() => {
                handleCancelPlan();
              }}
            >
              {t("cancelText")}
            </button>
          </div>
        ) : (
          <></>
        )} */}
      </>
    );
  };

  const SubscriptionComponent = ({
    subscriptionDetail,
    user,
    changelanguage,
    planDuration,
  }) => {
    return subscriptionDetail.map((item) => {
      const subtitles = JSON.parse(item.subtitle);

      return (
        <div
          className="col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-3"
          key={item.id}
        >
          <div className="my_subscription_div h-100 current" style={{ borderRadius: "13px" }}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/subscribed_new.svg`}
            />
            <h4>
              {changelanguage === "EN"
                ? item.name_en
                : changelanguage === "FR"
                  ? item.name_fr
                  : item.name_port}
            </h4>
            <label>
              {changelanguage === "EN"
                ? item.title_en
                : changelanguage === "FR"
                  ? item.title_fr
                  : item.title_port}
            </label>
            <h2>
              $
              {planDuration === "monthly"
                ? item.price_monthly
                : item.price_yearly}
              <small>/{planDuration}</small>
            </h2>
            {getButton(item, user, planDuration)}

            <ul>
              {subtitles.map((val, idx) => (
                <li key={idx}>
                  {changelanguage === "EN"
                    ? val.subtitle_en
                    : changelanguage === "FR"
                      ? val.subtitle_fr
                      : val.subtitle_port}
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    getPlanDetails();
  }, [getPlanDetails]);

  return (
    <>
      <main>
        <Innerheader />
        <div className="wrapper">
          <Sidebar />
          <div className="content flex-grow-1">
            <section className="category_main_title">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 mb-4 ">
                    <div className="main_gray">
                      <div className="row">
                        <div className="col-12">
                          {
                            user && user?.is_auto_subscription_cancel == 1 && (
                              <div class="alert alert-danger" role="alert">
                                {t("autoRenewalStopError")}
                              </div>
                            )
                          }
                          <div className="login_header">
                            <a
                              className="cursor-pointer"
                              onClick={() => navigate(-1)}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/left_arrow.svg"
                                }
                              />{" "}
                              {t("back")}
                            </a>
                            {/* {user?.is_subscribed == 1 && user?.is_subscription_cancel == 0 && (
                              <button
                                className="btn card-details-btn"
                                type="button"
                                onClick={() => { setModalOpen(true) }}>
                                {t("updateCardDetails")}
                              </button>
                            )} */}
                          </div>
                        </div>
                      </div>
                      <div className="row mb-5">
                        <div className="col-md-12">
                          <h5 className="mb-2 fw-bold text-center">
                            {t("findYourPlanTitle")}
                          </h5>
                          {/* <p className="mx-auto text-center">
                            {t("findYourPlanDescription")}
                          </p> */}
                          {/* {user?.end_date ? (
                            <p className="mx-auto fw-bold text-center mt-3">
                              {t("currentPlanEnd", {
                                plan: _.capitalize(user?.subscribed_type) + " / " + _.capitalize(user?.subscription_duration),
                                endDate: moment(user?.end_date).format(
                                  "DD-MMM-YYYY"
                                ),
                              })}
                            </p>
                          ) : (
                            ""
                          )} */}
                          <div className="row justify-content-center w-100">
                            <div className="col-12">
                              <div className="subscription text-center">
                                <div className="stc_active justify-content-center">
                                  <p
                                    className={
                                      planDuration === "monthly"
                                        ? "fw-bold"
                                        : ""
                                    }
                                  >
                                    {t("BillMonthly")}
                                  </p>
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      defaultChecked=""
                                      onChange={(e) => handleswitch(e)}
                                    />
                                    <span className="slider round" />
                                  </label>
                                  <p
                                    className={
                                      planDuration === "yearly" ? "fw-bold" : ""
                                    }
                                  >
                                    {t("BillAnnualy")}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row  justify-content-center">
                        {/* <div className="col-lg-3 col-md-5 col-sm-6 col-12 mb-3">
                          <div className="my_subscription_div h-100">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/subscription.svg"
                              }
                            />
                            <h4> {t("Proname")}</h4>
                            <label>{t("Prodescription")}</label>
                            <h2>
                              ${proyearPlan}
                              <small>/{handlePlan}</small>
                            </h2>
                            <ul>
                              <li>{t("canAccessTemplates")}</li>
                              <li>{t("canDownloadHighQuality")}</li>
                              <li>{t("noWatermark")}</li>
                            </ul>
                            <a href="#" className="subs_btn_1">
                              {t("cta")}
                            </a>
                          </div>
                        </div> */}

                        <SubscriptionComponent
                          subscriptionDetail={subscriptionDetail}
                          user={user}
                          changelanguage={changelanguage}
                          planDuration={planDuration}
                        />
                        {/* {subscriptionDetail?.map((item) => {
                          return (
                            <div className="col-xxl-3 col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
                              <div className="my_subscription_div h-100 current">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/subscribed.svg"
                                  }
                                />
                                <h4>
                                  {" "}
                                  {changelanguage === "EN"
                                    ? item?.name_en
                                    : changelanguage === "FR"
                                    ? item?.name_fr
                                    : item?.name_port}
                                </h4>
                                <label>
                                  {" "}
                                  {changelanguage === "EN"
                                    ? item?.title_en
                                    : changelanguage === "FR"
                                    ? item?.title_fr
                                    : item?.title_port}
                                </label>
                                <h2>
                                  $
                                  {planDuration === "monthly"
                                    ? item?.price_monthly
                                    : item?.price_yearly}
                                  <small>/{planDuration}</small>
                                </h2>
                                <ul>
                                  {JSON.parse(item?.subtitle)?.map((val) => {
                                    return (
                                      <li>
                                        {changelanguage === "EN"
                                          ? val?.subtitle_en
                                          : changelanguage === "FR"
                                          ? val?.subtitle_fr
                                          : val?.subtitle_port}
                                      </li>
                                    );
                                  })}
                                </ul>

                                {getButton(item)}
                              </div>
                            </div>
                          );
                        })} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {paymentModalOpen && (
              <FinalSubPayment
                paymentModalOpen={paymentModalOpen}
                setPaymentModalOpen={setPaymentModalOpen}
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
                planDuration={planDuration}
              />
            )}
            {isModalOpen && (
              <ChangeCardDetails
                isModalOpen={isModalOpen}
                setModalOpen={setModalOpen}
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
                planDuration={planDuration}
              />
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Subscription;
