// Loader.js
import React from 'react';
import './Loader.css';
import Lottie from 'react-lottie';
import loadingAnimation from '../loder.json'
import { useTranslation } from 'react-i18next';
const CustomLoader = () => {
    const { t, i18n } = useTranslation();
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <>
            <div className="loader-container">
                <div className='text-center'>
                    {/* <Lottie options={defaultOptions} height={80} width={80} style={{ margin: '0' }} /> */}
                    <img src={process.env.PUBLIC_URL + "/assets/images/loader.gif"} alt=''></img>
                    <p>{t("pleaseWait")}</p>
                </div>
            </div>
        </>
    );
};

export default CustomLoader;
