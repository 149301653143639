import React, { useEffect, useState } from "react";
import Innerheader from "../../components/Innerheader";
import { useTranslation } from "react-i18next";
import Sidebar from "../../components/Sidebar";
import Swal from "sweetalert2";
import AddMember from "./components/AddMember";
import { deleteTeamMember, getTeam } from "../../api/apiHandler";
import moment from "moment/moment";
import { showPromiseNotification } from "../../Common/notification";

const My_team = () => {
  const { t } = useTranslation();

  const [addmember, setaddmember] = useState(false);
  const [member, setMember] = useState([]);
  console.log("member: ", member);

  const handleDelete = (data) => {
    console.log("data: ", data);
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this member!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          id: data?.id,
        };

        const myPromise = new Promise((resolve, reject) =>
          deleteTeamMember(payload).then((response) => {
            if (response.code === 200) {
              setMember((prev) => prev.filter((val) => val.id !== data?.id));
              resolve(response);
            } else {
              reject(response);
            }
          })
        );
        showPromiseNotification(myPromise);
      }
    });
  };

  const openDashboardModal = () => {
    setaddmember(true);
  };

  const closeDashboardModal = () => {
    setaddmember(false);
  };

  useEffect(() => {
    getTeam({}).then((response) => {
      if (response.code === 200) {
        console.log("response: ", response);
        setMember(response.data);
      }
    });
  }, []);

  return (
    <>
      <main>
        <Innerheader />
        <div className="wrapper">
          <Sidebar />
          <div className="content flex-grow-1">
            <section className="category_main_title">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 mb-4 d-flex justify-content-between align-items-center flex-wrap">
                    <h2 className="section_title">{t("myTeam")}</h2>
                    {member && member.length < 10 && (
                      <a
                        onClick={openDashboardModal}
                        // data-bs-toggle="modal"
                        className="view_all"
                      >
                        {t("addNewMember")}
                      </a>

                    )}
                  </div>
                  <div className="col-12 d-flex flex-column">
                    {member?.length ? (
                      <div className="member_list">
                        <ul>
                          {member?.map((item) => {
                            return (
                              <li className=" team-member-custom">
                                <div className="d-flex align-items-center ">
                                  <div className="member_img">
                                    <a href="javascript:void(0);">
                                      <img src={item?.profile_image} />
                                    </a>
                                  </div>
                                  <div className="member_name cursor-pointer">
                                    <p className="fw-bolder fs-5 mb-2">
                                      {item?.full_name}
                                    </p>
                                    <div
                                      className="fw-light"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {/* <p className="d-inline-block ">
                                        {item?.original_name}
                                      </p>
                                      {!item?.original_name?.length && (
                                        <p
                                          className="d-inline-block  border border-2 border-secondary mx-2"
                                          style={{
                                            height: "6px",
                                            width: "6px",
                                            borderRadius: "50%",
                                          }}
                                        ></p>
                                      )} */}
                                      <p className="d-inline-block ">
                                        {item?.email}
                                      </p>
                                      {!item?.user_id && (
                                        <>
                                          <p
                                            className="d-inline-block  border border-2 border-secondary mx-2"
                                            style={{
                                              height: "6px",
                                              width: "6px",
                                              borderRadius: "50%",
                                            }}
                                          ></p>
                                          <p class="d-inline-block badge rounded-pill badge-custom bg-secondary ">
                                            User doesn't exist
                                          </p>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="member_btn">
                                  <button
                                    onClick={() => {
                                      handleDelete(item);
                                    }}
                                    className="btn btn_member"
                                  >
                                    Delete
                                  </button>
                                  <span className="date">
                                    {moment(item?.created_at).format(
                                      "DD MMM YYYY"
                                    )}
                                  </span>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : (
                      <div
                        style={{ height: "100px" }}
                        className="fw-light d-flex justify-content-center align-items-center  w-100"
                      >
                        {t("noDataFound")}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
      {addmember && (
        <AddMember
          addmember={addmember}
          closeDashboardModal={closeDashboardModal}
          setMember={setMember}
        />
      )}
    </>
  );
};

export default My_team;
