import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer2 from "../../components/Footer2";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../../components/ScrollToTop";
import Header2 from "../../components/Header2";
import Footer from "../../components/Footer";

import { getToken } from "../../Common/LocalStorageService";
import { useSelector } from "react-redux";
import { getFaqs } from "../../api/apiHandler";

const Faqs = () => {
  const [faqList, setFaqList] = useState([]);
  const changelanguage = useSelector((state) => state.changelanguage);
  const { t, i18n } = useTranslation();
  // const [scroll, setScroll] = useState(true)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getFaqs({}).then((response) => {
      console.log("response:======> ", response);
      if (response.code === 200) {
        setFaqList(response.data);
      }
    });
  }, []);
  return (
    <div>
      {/* {scroll ? <ScrollToTop /> : null} */}
      {getToken() ? <Header2 /> : <Header />}
      <section className="about_us_page" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-5 col-12 ">
              <div className="left_content_img">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/faq.svg"}
                  className="img-fluid"
                  alt="about"
                />
              </div>
            </div>
            <div className="col-lg-8 col-md-8 col-sm-7 col-12 ">
              <div className="right_side_content">
                <div className="main_title">
                  <h1>
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/faq_icon.svg"
                      }
                    />
                    {t("faqTitle")}
                  </h1>
                </div>
                <div className="faq_main">
                  <div className="accordion" id="accordionExample">
                    {faqList?.map((item, index) => {
                      return (
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={`faq_${index}`}>
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapseOne_${index}`}
                              aria-expanded={index === 0 ? "true" : "false"}
                              aria-controls="collapseOne"
                            >
                              {changelanguage === "EN"
                                ? item?.question_en
                                : changelanguage === "FR"
                                  ? item?.question_fr
                                  : item?.question_port}
                            </button>
                          </h2>
                          <div
                            id={`collapseOne_${index}`}
                            className={`accordion-collapse collapse ${index === 0 ? "show" : ""
                              }`}
                            aria-labelledby={`faq_${index}`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>
                                {changelanguage === "EN"
                                  ? item?.answer_en
                                  : changelanguage === "FR"
                                    ? item?.answer_fr
                                    : item?.answer_port}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      {/* <Footer2 /> */}
    </div>
  );
};

export default Faqs;
