import i18n from "i18next";
import { initReactI18next } from "react-i18next";


// Importing translation files

import translationEN from "../src/locales/en/translation.json";
import translationFR from "../src/locales/fr/translation.json";
import translationPT from "../src/locales/pt/translation.json";

const storedLanguage = localStorage.getItem('selectedLanguage') || 'EN';


//Creating object with the variables of imported translation files
const resources = {
    EN: {
        translation: translationEN,
    },
    FR: {
        translation: translationFR,
    },
    PT: {
        translation: translationPT,
    }
};

//i18N Initialization

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: 'EN',
        resources,
        lng: storedLanguage,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;