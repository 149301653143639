import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  deleteInvitedTeamMember,
  getTeamInvitation,
  getTemplateInvitation,
  inviteTeamMember,
} from "../../../api/apiHandler";
import Lottie from 'react-lottie';
import loadingAnimation from '../../Editor/loder.json'
import { showPromiseNotification } from "../../../Common/notification";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

const InviteMembers = (props) => {
  const { isInviteMemberModalOpen, setIsInviteMemberModalOpen } = props;
  const { t } = useTranslation();
  const { id } = useParams();
  const [team, setTeam] = useState([]);
  console.log('team: ', team);
  const [isSubmitting, setIsSubmitting] = useState(-1);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const handleMemberInvite = useCallback(
    (data, index) => {
      setIsSubmitting(index)
      const payload = {
        template_id: id,
        userId: data?.user_id,
        team_id: data?.id,
      };
      console.log('payload: ', payload);

      const myPromise = new Promise((resolve, reject) =>
        inviteTeamMember(payload).then((response) => {
          if (response.code === 200) {
            setTeam((prev) =>
              prev?.map((val) => {
                if (val.id === data?.id) {
                  val = { ...val, ...response.data };
                }
                return val;
              })
            );
            // setIsInviteMemberModalOpen(false);
            setIsSubmitting(-1)
            resolve(response);
          } else {
            reject(response);
          }
        })
      );
      showPromiseNotification(myPromise);
    },
    [id]
  );
  // const handleDeleteMember = useCallback(
  //   (data) => {
  //     const payload = {
  //       template_id: id,
  //       userId: data?.user_id,
  //       team_id: data?.id,
  //     };

  //     const myPromise = new Promise((resolve, reject) =>
  //       deleteInvitedTeamMember(payload).then((response) => {
  //         if (response.code === 200) {
  //           setTeam((prev) =>
  //             prev?.map((val) => {
  //               if (val.id === data?.id) {
  //                 val = { ...val, is_invite: 0 };
  //               }
  //               return val;
  //             })
  //           );
  //           // setIsInviteMemberModalOpen(false);
  //           resolve(response);
  //         } else {
  //           reject(response);
  //         }
  //       })
  //     );
  //     showPromiseNotification(myPromise);
  //   },
  //   [id]
  // );

  const handleDeleteMember = (data, index) => {
    console.log("data: ", data);
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this member!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsSubmitting(index)
        const payload = {
          template_id: id,
          userId: data?.user_id,
          team_id: data?.id,
        };
        const myPromise = new Promise((resolve, reject) =>
          deleteInvitedTeamMember(payload).then((response) => {
            if (response.code === 200) {
              setTeam((prev) =>
                prev?.map((val) => {
                  if (val.id === data?.id) {
                    val = { ...val, is_invite: 0 };
                  }
                  return val;
                })
              );
              // setIsInviteMemberModalOpen(false);
              setIsSubmitting(-1)
              resolve(response);
            } else {
              reject(response);
            }
          })
        );
        showPromiseNotification(myPromise);
      }
    });
  };

  useEffect(() => {
    getTemplateInvitation({ template_id: id }).then((response) => {
      if (response.code === 200) {
        setTeam(response.data);
      }
    });
  }, [id]);

  return (
    <>
      <div
        className={`modal fade ${isInviteMemberModalOpen ? "show" : ""}`}
        id="otp"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style={{ display: isInviteMemberModalOpen ? "block" : "none" }}
      >
        {/* <div className="modal" tabIndex={-1} id="otp"> */}
        <div className="modal-dialog modal-dialog-centered modal-lg ">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="custom_btn"
                onClick={() => {
                  setIsInviteMemberModalOpen(false);
                }}
                aria-label="Close"
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/close.svg"}
                />
              </button>
              <section className="category_main_title">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 mb-4 d-flex justify-content-between align-items-center flex-wrap">
                      <h2 className="section_title">{t("inviteMember")}</h2>
                    </div>
                    <div className="col-12">
                      {team?.length ? (
                        <div className="member_list">
                          <ul>
                            {team?.map((item, index) => {
                              return (
                                <li>
                                  <div className="member_img">
                                    <a href="#">
                                      <img src={item?.profile_image} />
                                    </a>
                                  </div>
                                  <div className="member_name cursor-pointer">
                                    <p className="fw-bolder fs-5 mb-2">
                                      {item?.full_name}
                                    </p>
                                    <p
                                      className="fw-light"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {item?.original_name}
                                      {item?.original_name?.length && (
                                        <span
                                          className="d-inline-block  border border-2 border-secondary mx-2"
                                          style={{
                                            height: "6px",
                                            width: "6px",
                                            borderRadius: "50%",
                                          }}
                                        ></span>
                                      )}
                                      {item?.email}
                                      {!item?.user_id && (
                                        <>
                                          <span
                                            className="d-inline-block  border border-2 border-secondary mx-2"
                                            style={{
                                              height: "6px",
                                              width: "6px",
                                              borderRadius: "50%",
                                            }}
                                          ></span>
                                          <span class="badge rounded-pill badge-custom bg-secondary">
                                            User doesn't exist
                                          </span>
                                        </>
                                      )}
                                    </p>
                                  </div>
                                  {item?.is_invite === 1 ? (
                                    <div className="member_btn">
                                      <button
                                        onClick={() => {
                                          handleDeleteMember(item, index);
                                        }}
                                        className="btn btn_member"
                                        disabled={isSubmitting == index}
                                        style={{ display: 'flex', justifyContent: "center" }}
                                      >
                                        {/* Delete */}
                                        {t("delete")}
                                        {/* {
                                          isSubmitting !== index ?
                                            (
                                              <>
                                                {t("pleaseWait")}
                                                < Lottie options={defaultOptions} height={25} width={80} style={{ margin: '0' }} />
                                              </>
                                            )
                                            : t("delete")
                                        } */}
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="member_btn user_option">
                                      <button
                                        className="btn btn-login"
                                        onClick={() => {
                                          handleMemberInvite(item, index);
                                        }}
                                        disabled={isSubmitting == index}
                                        style={{ display: 'flex', justifyContent: "center", textAlign: "center" }}
                                      >
                                        {
                                          isSubmitting == index ?
                                            (
                                              <>
                                                {t("pleaseWait")}
                                                < Lottie options={defaultOptions} height={25} width={80} style={{ margin: '0' }} />
                                              </>
                                            )
                                            : t("add")
                                        }
                                      </button>
                                    </div>
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : (
                        <div
                          style={{ height: "100px" }}
                          className="fw-light d-flex justify-content-center align-items-center  w-100"
                        >
                          {t("noDataFound")}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      {
        isInviteMemberModalOpen && (
          <div className="modal-backdrop fade show"></div>
        )
      }
    </>
  );
};

export default InviteMembers;
