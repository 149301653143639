import React from 'react'
import Innerheader from '../../components/Innerheader'
import Sidebar from '../../components/Sidebar'
import { useTranslation } from "react-i18next";


const Invite_member = () => {
    const { t, i18n } = useTranslation();

    return (
        <>
            <main>
                <Innerheader />
                <div className="wrapper">
                    <Sidebar />
                    <div className="content flex-grow-1">
                        <section className="category_main_title">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12 mb-4 d-flex justify-content-between align-items-center flex-wrap">
                                        <h2 className="section_title">{t('inviteMember')}</h2>
                                    </div>
                                    <div className="col-12">
                                        <div className="member_list">
                                            <ul>
                                                <li>
                                                    <div className="member_img">
                                                        <a href="#">
                                                            <img src="assets/images/member-1.png" />
                                                        </a>
                                                    </div>
                                                    <div className="member_name">
                                                        <h4>
                                                            <a href="#"> Michael Wong</a>
                                                        </h4>
                                                        <p>
                                                            <a href="#">Michaelwong@mail.com</a>
                                                        </p>
                                                    </div>
                                                    <div className="member_btn">
                                                        <a href="#" className="btn btn-login">
                                                            Add
                                                        </a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="member_img">
                                                        <a href="#">
                                                            <img src="assets/images/member-1.png" />
                                                        </a>
                                                    </div>
                                                    <div className="member_name">
                                                        <h4>
                                                            <a href="#"> Michael Wong</a>
                                                        </h4>
                                                        <p>
                                                            <a href="#">Michaelwong@mail.com</a>
                                                        </p>
                                                    </div>
                                                    <div className="member_btn">
                                                        <a href="#" className="btn btn-login">
                                                            Add
                                                        </a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="member_img">
                                                        <a href="#">
                                                            <img src="assets/images/member-1.png" />
                                                        </a>
                                                    </div>
                                                    <div className="member_name">
                                                        <h4>
                                                            <a href="#"> Michael Wong</a>
                                                        </h4>
                                                        <p>
                                                            <a href="#">Michaelwong@mail.com</a>
                                                        </p>
                                                    </div>
                                                    <div className="member_btn">
                                                        <a href="#" className="btn btn-login">
                                                            Add
                                                        </a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="member_img">
                                                        <a href="#">
                                                            <img src="assets/images/member-1.png" />
                                                        </a>
                                                    </div>
                                                    <div className="member_name">
                                                        <h4>
                                                            <a href="#"> Michael Wong</a>
                                                        </h4>
                                                        <p>
                                                            <a href="#">Michaelwong@mail.com</a>
                                                        </p>
                                                    </div>
                                                    <div className="member_btn">
                                                        <a href="#" className="btn btn-login">
                                                            Add
                                                        </a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="member_img">
                                                        <a href="#">
                                                            <img src="assets/images/member-1.png" />
                                                        </a>
                                                    </div>
                                                    <div className="member_name">
                                                        <h4>
                                                            <a href="#"> Michael Wong</a>
                                                        </h4>
                                                        <p>
                                                            <a href="#">Michaelwong@mail.com</a>
                                                        </p>
                                                    </div>
                                                    <div className="member_btn">
                                                        <a href="#" className="btn btn-login">
                                                            Add
                                                        </a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="member_img">
                                                        <a href="#">
                                                            <img src="assets/images/member-1.png" />
                                                        </a>
                                                    </div>
                                                    <div className="member_name">
                                                        <h4>
                                                            <a href="#"> Michael Wong</a>
                                                        </h4>
                                                        <p>
                                                            <a href="#">Michaelwong@mail.com</a>
                                                        </p>
                                                    </div>
                                                    <div className="member_btn">
                                                        <a href="#" className="btn btn-login">
                                                            Add
                                                        </a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="member_img">
                                                        <a href="#">
                                                            <img src="assets/images/member-1.png" />
                                                        </a>
                                                    </div>
                                                    <div className="member_name">
                                                        <h4>
                                                            <a href="#"> Michael Wong</a>
                                                        </h4>
                                                        <p>
                                                            <a href="#">Michaelwong@mail.com</a>
                                                        </p>
                                                    </div>
                                                    <div className="member_btn">
                                                        <a href="#" className="btn btn-login">
                                                            Add
                                                        </a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="member_img">
                                                        <a href="#">
                                                            <img src="assets/images/member-1.png" />
                                                        </a>
                                                    </div>
                                                    <div className="member_name">
                                                        <h4>
                                                            <a href="#"> Michael Wong</a>
                                                        </h4>
                                                        <p>
                                                            <a href="#">Michaelwong@mail.com</a>
                                                        </p>
                                                    </div>
                                                    <div className="member_btn">
                                                        <a href="#" className="btn btn-login">
                                                            Add
                                                        </a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="member_img">
                                                        <a href="#">
                                                            <img src="assets/images/member-1.png" />
                                                        </a>
                                                    </div>
                                                    <div className="member_name">
                                                        <h4>
                                                            <a href="#"> Michael Wong</a>
                                                        </h4>
                                                        <p>
                                                            <a href="#">Michaelwong@mail.com</a>
                                                        </p>
                                                    </div>
                                                    <div className="member_btn">
                                                        <a href="#" className="btn btn-login">
                                                            Add
                                                        </a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="member_img">
                                                        <a href="#">
                                                            <img src="assets/images/member-1.png" />
                                                        </a>
                                                    </div>
                                                    <div className="member_name">
                                                        <h4>
                                                            <a href="#"> Michael Wong</a>
                                                        </h4>
                                                        <p>
                                                            <a href="#">Michaelwong@mail.com</a>
                                                        </p>
                                                    </div>
                                                    <div className="member_btn">
                                                        <a href="#" className="btn btn-login">
                                                            Add
                                                        </a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="member_img">
                                                        <a href="#">
                                                            <img src="assets/images/member-1.png" />
                                                        </a>
                                                    </div>
                                                    <div className="member_name">
                                                        <h4>
                                                            <a href="#"> Michael Wong</a>
                                                        </h4>
                                                        <p>
                                                            <a href="#">Michaelwong@mail.com</a>
                                                        </p>
                                                    </div>
                                                    <div className="member_btn">
                                                        <a href="#" className="btn btn-login">
                                                            Add
                                                        </a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="member_img">
                                                        <a href="#">
                                                            <img src="assets/images/member-1.png" />
                                                        </a>
                                                    </div>
                                                    <div className="member_name">
                                                        <h4>
                                                            <a href="#"> Michael Wong</a>
                                                        </h4>
                                                        <p>
                                                            <a href="#">Michaelwong@mail.com</a>
                                                        </p>
                                                    </div>
                                                    <div className="member_btn">
                                                        <a href="#" className="btn btn-login">
                                                            Add
                                                        </a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="member_img">
                                                        <a href="#">
                                                            <img src="assets/images/member-1.png" />
                                                        </a>
                                                    </div>
                                                    <div className="member_name">
                                                        <h4>
                                                            <a href="#"> Michael Wong</a>
                                                        </h4>
                                                        <p>
                                                            <a href="#">Michaelwong@mail.com</a>
                                                        </p>
                                                    </div>
                                                    <div className="member_btn">
                                                        <a href="#" className="btn btn-login">
                                                            Add
                                                        </a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="member_img">
                                                        <a href="#">
                                                            <img src="assets/images/member-1.png" />
                                                        </a>
                                                    </div>
                                                    <div className="member_name">
                                                        <h4>
                                                            <a href="#"> Michael Wong</a>
                                                        </h4>
                                                        <p>
                                                            <a href="#">Michaelwong@mail.com</a>
                                                        </p>
                                                    </div>
                                                    <div className="member_btn">
                                                        <a href="#" className="btn btn-login">
                                                            Add
                                                        </a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="member_img">
                                                        <a href="#">
                                                            <img src="assets/images/member-1.png" />
                                                        </a>
                                                    </div>
                                                    <div className="member_name">
                                                        <h4>
                                                            <a href="#"> Michael Wong</a>
                                                        </h4>
                                                        <p>
                                                            <a href="#">Michaelwong@mail.com</a>
                                                        </p>
                                                    </div>
                                                    <div className="member_btn">
                                                        <a href="#" className="btn btn-login">
                                                            Add
                                                        </a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="member_img">
                                                        <a href="#">
                                                            <img src="assets/images/member-1.png" />
                                                        </a>
                                                    </div>
                                                    <div className="member_name">
                                                        <h4>
                                                            <a href="#"> Michael Wong</a>
                                                        </h4>
                                                        <p>
                                                            <a href="#">Michaelwong@mail.com</a>
                                                        </p>
                                                    </div>
                                                    <div className="member_btn">
                                                        <a href="#" className="btn btn-login">
                                                            Add
                                                        </a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </main>

        </>
    )
}

export default Invite_member