import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import $ from "jquery";
import { useTranslation } from "react-i18next";
import {
  addTemplate,
  editTemplate,
  getAllCategories,
  mp4Download,
} from "../../../api/apiHandler";
import { TagsInput } from "react-tag-input-component";
import uploadToS3, {
  generateRandomFileName,
  uploadOrUpadateJSONToS3,
} from "../../../Common/s3AwsService";
import {
  showErrorMessage,
  showPromiseNotification,
} from "../../../Common/notification";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import Lottie from "react-lottie";
import loadingAnimation from "../loder.json";
window.jQuery = window.$ = $;
require("jquery-nice-select");

const AddTemplate = (props) => {
  const {
    isAddTemplateModalOpen,
    setIsTemplateModalOpen,
    store,
    submitBackClick,
    type,
    templateData,
    setTemplateData,
    isNewDesign,
    submitPremiumClick,
    templateChange,
    fillCategoryData,
    setIsSaveGoing,
    fontList
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [category, setCategory] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  console.log("category11: ", category);
  const isVideo = templateChange?.pages?.[0]?.children?.some(
    (item) => item?.type === "video"
  );
  console.log("isVideo123: ", isVideo);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("nameIsRequired")),
  });
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      colors: [],
    },
  });

  console.log("watch: ", watch());

  console.log("errors: ", errors);

  useEffect(() => {
    $(document).ready(function () {
      $("select").niceSelect();
    });
  }, [setValue]);

  const colors = [
    "#000000",
    "#FFFFFF",
    "#C0C0C0",
    "#808080",
    "#800000",
    "#FF0000",
    "#800080",
    "#FF00FF",
    "#008000",
    "#00FF00",
    "#808000",
    "#FFFF00",
    "#000080",
    "#0000FF",
    "#008080",
    "#00FFFF",
  ];

  useEffect(() => {
    getAllCategories({}).then((response) => {
      console.log("response: cateeee", response);
      if (response.code === 200) {
        setCategory(response?.data);
      } else {
        setCategory([]);
      }
    });
  }, []);

  const getSubCategory = useCallback((id) => {
    getAllCategories({ category_id: id }).then((response) => {
      console.log("response: cateeee", response);
      if (response.code === 200) {
        setSubcategory(response?.data);
      } else {
        setSubcategory([]);
        showErrorMessage(response?.message);
      }
    });
  }, []);

  const handleColorChange = (color) => {
    const isColorSelected = watch("colors").some((c) => c.color === color);
    if (isColorSelected) {
      // If the color is already selected, remove it
      setValue(
        "colors",
        watch("colors").filter((c) => c.color !== color)
      );
    } else {
      // If the color is not selected, add it
      setValue("colors", [...watch("colors"), { color }]);
    }
  };
  async function fetchVideoAsFile(videoUrl, fileName) {
    // Fetch the video data from the URL
    const response = await fetch(videoUrl);
    const blob = await response.blob();

    // Create a File object from the Blob
    const file = new File([blob], fileName, { type: blob.type });

    // Now you can upload the file to S3 or handle it as needed
    return file;
  }

  const onSubmit = useCallback(
    async (data) => {
      setIsSaveGoing((prev) => !prev);
      console.log("data:====> ", data);
      console.log("store====>", store);
      setIsSubmitting(true);
      const { subcategory, category, ...restPayload } = data;
      // handle form submission
      const myPromise = new Promise(async (resolve, reject) => {
        const watermarkIds = [];
        await store.toJSON().pages.forEach((page) => {
          const filteredChildren = page
            // .toJSON()
            .children.filter((child) => child.name == "watermark");
          console.log("filteredChildren: ", filteredChildren);

          watermarkIds.push(...filteredChildren?.map(item => item.id));
        });
        await store.deleteElements(watermarkIds);
        const jsonData = store.toJSON();
        console.log('store.toJSON():132 ', store.toJSON());
        const fileName = generateRandomFileName();

        const [thumbnailResponse, jsonUploadResponse] = await Promise.all([
          store
            .toBlob({
              pixelRatio: 0.4,
              fileName,
              mimeType: "image/webp",
              // mimeType: isVideo ? "image/gif" : "image/webp",
            })
            .then((response) => {
              console.log('responseggg: ', response);
              console.log('store.toJSON():111 ', store.toJSON());
              response.name = `${fileName}.${response.type.split("/")[1]}`;
              return uploadToS3(response, response.name, "thumbnails");
            })
            .then((response) => {
              console.log("Thumbnail S3 Key", response.key);
              return response.key;
            })
            .catch((error) => {
              console.error("Thumbnail upload failed:", error);
              throw error;
            }),
          uploadOrUpadateJSONToS3(jsonData, `${fileName}.json`, "Templates")
            .then((response) => {
              console.log("JSON S3 KEY", response.key);
              return response.key;
            })
            .catch((error) => {
              console.error("JSON upload failed:", error);
              throw error;
            }),
        ]);
        const [thumbnailMainResponse] = await Promise.all([
          store
            .toBlob({
              pixelRatio: 1,
              fileName,
              mimeType: "image/webp",
              // mimeType: isVideo ? "image/gif" : "image/webp",
            })
            .then((response) => {
              console.log('response: gif check', response);
              response.name = `Main${fileName}.${response.type.split("/")[1]}`;
              return uploadToS3(response, response.name, "thumbnails");
            })
            .then((response) => {
              console.log("Thumbnail S3 Key", response.key);
              return response.key;
            })
            .catch((error) => {
              console.error("Thumbnail upload failed:", error);
              throw error;
            }),
        ]);
        const payload = {
          ...restPayload,
          category_id: category,
          subcategory_id: subcategory,
          template: jsonUploadResponse,
          thumbnail: thumbnailResponse,
          main_thumbnail: thumbnailMainResponse,
          colors: data.colors.map((item) => item.color),
          is_video: isVideo ? 1 : 0,
        };
        // video download
        const UploadVideoJson = await store.toJSON();
        console.log("UploadVideoJson: ", UploadVideoJson);

        // Usage
        if (isVideo == 1 && type != "edit") {
          const fileNameMainImage = generateRandomFileName();
          let newFonts = await store.toJSON()?.pages[0].children
            .filter((res) => res?.type === 'text' && res.fontFamily?.length > 0)
            // .map((res) => {res.fontFamily});
            .map((res) => {
              let fonUrl = fontList.find((font) => {
                return res.fontFamily === font.name;  // Ensure the comparison is returned
              });
              console.log(' newStore fonUrl: ', fonUrl);
              if (fonUrl && fonUrl?.image) {
                store.addFont({
                  fontFamily: res.fontFamily,
                  url: fonUrl ? fonUrl?.image : ""
                })
              }
            })
          const jsonData = store.toJSON();
          const req = await fetch(
            `https://api.polotno.com/api/renders?KEY=${process.env.REACT_APP_POLOTNO_KEY}`,
            {
              method: "POST",
              headers: {
                // it is important to set a json content type
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                // polotno json from store.toJSON()
                design: jsonData,
                format: "mp4",
                outputFormat: "url",
                // here you can pass other render options
                pixelRatio: 0.3,
                skipFontError: true,
                // see bellow for full details of options
              }),
            }
          );

          const { id: jonId } = await req.json();

          if (jonId) {
            for (let i = 0; i < 100; i++) {
              const req = await fetch(
                "https://api.polotno.com/api/renders/" +
                jonId +
                "?KEY=" +
                process.env.REACT_APP_POLOTNO_KEY
              );
              const job = await req.json();
              console.log("job: status===========>", job);
              if (job.status === "error") {
                alert("Error: " + job.error);
                break;
              }
              // if (job.status === "progress") {
              //   setProgress(job.progress);

              // }
              if (job.status === "done") {
                const url = job.output;

                // downloadFile(url, "export." + "mp4");
                // setProgress(0);
                // setIsSubmitting(false)

                await fetchVideoAsFile(url, fileNameMainImage)
                  .then(async (file) => {
                    console.log("File created:", file);
                    await uploadToS3(
                      file,
                      `${generateRandomFileName()}.mp4`,
                      "template_vidoes"
                    ).then(async (response) => {
                      console.log('payload147 response: ', response);
                      payload.preview_video = response.key;
                      if (type == "edit") {
                        await editTemplate({
                          template_id: templateData.id,
                          preview_video: response.key,
                          is_video: isVideo,
                        }).then((response) => {
                          console.log("response: ", response);
                          // if (response.code === 200) {

                          // } else {
                          // }
                        });
                      }
                    });
                  })
                  .catch((error) => {
                    console.error("Error fetching video:", error);
                  });
                break;
              }
              // wait a bit
              await new Promise((r) => setTimeout(r, 1000));
            }
          }

          // await mp4Download({
          //   template_data: UploadVideoJson,
          //   video_quality: 0.1
          // }).then(async (response) => {
          //   console.log("videoUrl response: ", response);
          //   if (response.code === 200) {
          //     // Create a hidden iframe
          //     const videoUrl = response.data; // Assuming response.data contains the video URL
          //     console.log('videoUrl: ', videoUrl);
          //     await fetchVideoAsFile(videoUrl, fileName)
          //       .then(async file => {
          //         console.log('File created:', file);
          //         await uploadToS3(
          //           file,
          //           `${generateRandomFileName()}.mp4`,
          //           "user"
          //         ).then((response) => {
          //           console.log('response: ', response);
          //           payload.preview_video = response.key;
          //           console.log('payload123: ', payload);
          //         });
          //       })
          //       .catch(error => {
          //         console.error('Error fetching video:', error);
          //       });
          //   } else {
          //     reject({ message: "Failed to convert into MP4" });
          //     return;
          //   }
          // })
        }

        if (id) {
          payload.main_template_id = id;
        }
        payload.category_id = fillCategoryData.category_id
        payload.subcategory_id = fillCategoryData.subcategory_id
        console.log('payload147: ', payload);

        setIsSaveGoing((prev) => !prev);
        if (type != "edit") {
          console.log('payload888: ', payload);
          addTemplate(payload).then((response) => {
            if (response.code === 200) {
              resolve(response);
              setIsTemplateModalOpen(false);
              if (submitBackClick) {
                navigate("/home");
              } else if (isNewDesign) {
                navigate("/add-template");
                store.clear();
                store.addPage();
              } else if (submitPremiumClick) {
                navigate("/subscription");
              } else {
                navigate(`/edit-template/${response.data.id}`);
              }
            } else {
              reject(response);
            }
          });
        } else {
          console.log('payload888: ', payload);
          editTemplate({ name: payload.name, template_id: templateData.id }).then(
            (response) => {
              console.log("response: ", response);
              if (response.code === 200) {
                resolve(response);
                setIsTemplateModalOpen(false);
                setTemplateData({
                  ...templateData,
                  name: response?.data?.name,
                });
                if (submitBackClick) {
                  navigate("/home");
                } else {
                  navigate(`/edit-template/${response.data.id}`);
                }
              } else {
                reject(response);
              }
            }
          );
        }
        showPromiseNotification(myPromise);
      });
    },
    [fillCategoryData.category_id, fillCategoryData.subcategory_id, fontList, id, isNewDesign, isVideo, navigate, setIsSaveGoing, setIsTemplateModalOpen, setTemplateData, store, submitBackClick, submitPremiumClick, templateData, type]

  );
  useEffect(() => {
    setValue("name", "New Design");
    if (templateData?.id) {
      console.log("templateData: ", templateData);
      setValue("name", templateData?.name);
    }
  }, [setValue, templateData]);

  return (
    <>
      <div
        className={`modal fade ${isAddTemplateModalOpen ? "show d-block" : "d-none"
          }`}
        tabIndex={-1}
        id="add-template-modal"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="custom_btn"
                onClick={() => setIsTemplateModalOpen(false)}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/close.svg"}
                  alt="close"
                />
              </button>
              <div className="modal-login-main">
                <h6 className="modal-heading">{t("designName")}</h6>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ul className="link-box-ul">
                    <li className="add-template-li">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className={`form-control `}
                        placeholder={t("name")}
                        {...register("name")}
                      />
                      {errors.name && (
                        <span className="text-danger fs-12px">
                          {errors.name.message}
                        </span>
                      )}
                    </li>
                    <li className="link-box-li">
                      <div className="form-group left_fixed2">
                        <button
                          type="submit"
                          className="btn btn-theme"
                          disabled={isSubmitting}
                          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                        >
                          {isSubmitting ? (
                            <>
                              {t("pleaseWait")}
                              {/* <img src={process.env.PUBLIC_URL + "/assets/images/loader.gif"} height={50} alt=''></img> */}
                              <Lottie
                                options={defaultOptions}
                                height={25}
                                width={80}
                                style={{ margin: "0" }}
                              />
                              {/* <div>
                              </div> */}
                            </>
                          ) : (
                            t("save")
                          )}
                        </button>
                      </div>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isAddTemplateModalOpen && (
        <div className="modal-backdrop fade show"></div>
      )}
    </>
  );
};

export default AddTemplate;
