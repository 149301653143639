import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getSubscription } from "../../../api/apiHandler";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../Common/LocalStorageService";

const Subscriptionplan = (props) => {
  const { setIsLogin } = props;
  const changelanguage = useSelector((state) => state.changelanguage);
  const navigate = useNavigate();
  const [planDuration, setPlanDuration] = useState("monthly");
  const [planfeatures, setPlanFeatures] = useState("free");
  const { t, i18n } = useTranslation();

  const [subscriptionDetail, setSubscriptionDetail] = useState([]);
  console.log("subscriptionDetail: ", subscriptionDetail);

  const handleswitch = (event) => {
    if (event.target.checked) {
      setPlanDuration("yearly");
    } else {
      setPlanDuration("monthly");
    }
  };

  const handlefeatures = (plantypefeature) => {
    setPlanFeatures(plantypefeature);
  };

  const getPlanDetails = useCallback(
    () =>
      getSubscription({}).then((response) => {
        if (response.code === 200) {
          setSubscriptionDetail(response.data);
        }
      }),
    []
  );

  const handleSubscription = useCallback(
    (e) => {
      getToken() ? navigate("/subscription") : setIsLogin(true);
    },
    [navigate, setIsLogin]
  );

  const [proDetail, enterpriseDetail] = useMemo(
    () => subscriptionDetail,
    [subscriptionDetail]
  );

  useEffect(() => {
    getPlanDetails();
  }, [getPlanDetails]);

  return (
    <>
      <section className="achivement_section subscription_plan" id="pricing">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-5 col-md-6 col-sm-12 col-12 mx-auto">
                <div
                  className="accordion accordian_main_custom"
                  id="accordionExample"
                >
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        {t("freetitle")}
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>{t("freecontent")}</p>
                        <div className="subscribe_bottom">
                          <h6>
                            <sub></sub>$0<small>/{planDuration}</small>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                        onClick={() => handlefeatures("pro")}
                      >
                        {changelanguage === "EN"
                          ? proDetail?.name_en
                          : changelanguage === "FR"
                          ? proDetail?.name_fr
                          : proDetail?.name_port}
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        {/* <p>{t("procontent")}</p> */}
                        <p>
                          {changelanguage === "EN"
                            ? proDetail?.title_en
                            : changelanguage === "FR"
                            ? proDetail?.title_fr
                            : proDetail?.title_port}
                        </p>
                        <div className="subscribe_bottom">
                          <h6>
                            $
                            {planDuration === "monthly"
                              ? proDetail?.price_monthly
                              : proDetail?.price_yearly}
                            <small>
                              /{planDuration}
                              {/* {t('proprice')} */}
                            </small>
                          </h6>
                          <button
                            type="button"
                            className="btn btn-white"
                            onClick={handleSubscription}
                          >
                            {t("getPro")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        onClick={() => handlefeatures("Enterprise")}
                      >
                        {changelanguage === "EN"
                          ? enterpriseDetail?.name_en
                          : changelanguage === "FR"
                          ? enterpriseDetail?.name_fr
                          : enterpriseDetail?.name_port}
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        {/* <p>{t("Enterpricecontent")}</p> */}
                        <p>
                          {changelanguage === "EN"
                            ? enterpriseDetail?.title_en
                            : changelanguage === "FR"
                            ? enterpriseDetail?.title_fr
                            : enterpriseDetail?.title_port}
                        </p>
                        <div className="subscribe_bottom">
                          <h6>
                            ${" "}
                            {planDuration === "monthly"
                              ? enterpriseDetail?.price_monthly
                              : enterpriseDetail?.price_yearly}
                            <small>/{planDuration}</small>
                          </h6>
                          <button
                            type="button"
                            className="btn btn-white"
                            onClick={handleSubscription}
                          >
                            {t("getEnterprice")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-6 col-12">
                <div className="title-text">
                  <h1
                    dangerouslySetInnerHTML={{ __html: t("pricingTitle") }}
                  ></h1>
                  <p>{t("pricingSubtitle")}</p>
                </div>
                <div className="subscription_title">
                  <div className="stc_active">
                    <p className={planDuration === "monthly" ? "fw-bold" : ""}>
                      {t("BillMonthly")}
                    </p>
                    <label className="switch">
                      <input
                        type="checkbox"
                        defaultChecked=""
                        onChange={(e) => handleswitch(e)}
                      />
                      <span className="slider round" />
                    </label>
                    <p className={planDuration === "yearly" ? "fw-bold" : ""}>
                      {t("BillAnnualy")}
                    </p>
                  </div>
                </div>

                <div className="suscription_bg">
                  {planfeatures === "free" ? (
                    <ul>
                      <li>{t("canAccessTemplates")}</li>
                      <li>{t("lowquality")}</li>
                      <li>{t("Watermarkintemplate")}</li>
                    </ul>
                  ) : planfeatures === "pro" ? (
                    <ul>
                      {proDetail?.subtitle &&
                        JSON.parse(proDetail?.subtitle).map((val, idx) => (
                          <li key={idx}>
                            {changelanguage === "EN"
                              ? val.subtitle_en
                              : changelanguage === "FR"
                              ? val.subtitle_fr
                              : val.subtitle_port}
                          </li>
                        ))}
                    </ul>
                  ) : (
                    <ul>
                      {enterpriseDetail?.subtitle &&
                        JSON.parse(enterpriseDetail?.subtitle).map(
                          (val, idx) => (
                            <li key={idx}>
                              {changelanguage === "EN"
                                ? val.subtitle_en
                                : changelanguage === "FR"
                                ? val.subtitle_fr
                                : val.subtitle_port}
                            </li>
                          )
                        )}
                    </ul>
                  )}

                  <a className="btn btn-theme" onClick={handleSubscription}>
                    {t("getStarted")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Subscriptionplan;
