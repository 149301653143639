import React, { useEffect, useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useTranslation } from "react-i18next";
import { getTestimonial } from "../../../api/apiHandler";
import TestimonialModal from "./TestimonialModal";

const Testimonials = (props) => {
  const { t } = useTranslation();
  const [testimonial, setTestimonial] = useState([]);
  const [isTestimonialOpen, setIsTestimonialOpen] = useState({
    isOpen: false,
    data: {},
  });

  useEffect(() => {
    getTestimonial({}).then((response) => {
      if (response.code === 200) {
        setTestimonial(response?.data);
      }
    });
  }, []);

  return (
    <section className="section_template testimonial" id="testimonial">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="title-text text-center">
              <h1
                dangerouslySetInnerHTML={{ __html: t("ourUserLoveUs") }}
              ></h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* <div className="owl-carousel owl-theme testimonial_slider"> */}
            {testimonial?.length > 0 && (
              <OwlCarousel
                className="owl-carousel owl-theme testimonial_slider "
                // loop
                // autoplay
                nav
                dots={false}
                stagePadding={50}
                navText={[
                  `<img src="${process.env.PUBLIC_URL}/assets/images/rating/left.svg" />`,
                  `<img src="${process.env.PUBLIC_URL}/assets/images/rating/right.svg" />`,
                ]}
                responsive={{
                  0: {
                    items: 1, // Show one item on screens smaller than 600px
                    margin: 15,
                    stagePadding:20
                  },
                  600: {
                    items: 2, // Show two items on screens between 600px and 900px
                    margin: 15,
                  },
                  1024: {
                    items: 3,
                    margin: 15,

                    // Show three items on screens larger than 900px
                  },
                }}
              >
                {testimonial?.map((item) => {
                  return (
                    <>
                      {/* <div className="item p-2"> */}
                      <div className="testimonial_main h-100">
                        <div className="custom-truncate">
                          <p>{item?.feedback}</p>
                        </div>
                        <div className="testimonial_rate">
                          <div className="rating">
                            {Array(item?.rating)
                              .fill(null)
                              ?.map((item) => {
                                return (
                                  <span>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/rating/star.svg"
                                      }
                                    />
                                  </span>
                                );
                              })}
                            {5 - item?.rating > 0 &&
                              Array(5 - item?.rating)
                                .fill(null)
                                ?.map((item) => {
                                  return (
                                    <span>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/rating/start_outline.svg"
                                        }
                                      />
                                    </span>
                                  );
                                })}
                          </div>
                        </div>
                        <div className="testimonial_profile">
                          <div className="user_profile">
                            <img src={item?.profile_image} alt="client" />
                          </div>
                          <h5>{item?.full_name}</h5>
                          <div className="d-flex justify-content-center align-items-center ">
                            <button
                              class="btn btn-theme w-auto text-center view-more"
                              onClick={() => {
                                setIsTestimonialOpen({
                                  isOpen: true,
                                  data: item,
                                });
                              }}
                            >
                              View more
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                    </>
                  );
                })}
                {/* </div> */}
              </OwlCarousel>
            )}
          </div>
        </div>
      </div>
      {isTestimonialOpen?.isOpen && (
        <TestimonialModal
          isTestimonialOpen={isTestimonialOpen}
          setIsTestimonialOpen={setIsTestimonialOpen}
        />
      )}
    </section>
  );
};

export default Testimonials;
