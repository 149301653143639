import * as Yup from "yup";
import i18n from "../i18n";
import { PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();
// console.log("watch:===> ", watch());

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const ValidationSchema = (fields) => {
  const schemaShape = {};

  // signup

  if (fields.includes("Fullname")) {
    schemaShape.Fullname = Yup.string()
      .required(i18n.t(`fullnameenter`))
      .test("fullname", i18n.t(`fullname`), (item) => {
        return item.trim().length >= 2;
        // return isPhoneValid(item);
      });
    // // .matches("^[a-zA-Z]+(?: [a-zA-Z]+)*$", i18n.t(`fullname`));
    // .matches("^[^\s].*[^\s]$", i18n.t(`fullname`));
  }

  if (fields.includes("Email")) {
    schemaShape.Email = Yup.string()
      // .trim("The email cannot include leading and trailing spaces")
      .required(i18n.t(`emailRequired`))
      .email(i18n.t(`emailInvalid`))
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, i18n.t(`emailInvalid`));
    // .strict(true);
  }

  if (fields.includes("ForgotpasswordEmail")) {
    schemaShape.forgotPasswordSchema = Yup.string()
      // .trim("The email cannot include leading and trailing spaces")
      .required(i18n.t(`emailRequired`))
      .email(i18n.t(`emailInvalid`))
      .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, i18n.t(`emailInvalid`));
    // .strict(true);
  }

  if (fields.includes("PhoneNumber")) {
    schemaShape.PhoneNumber = Yup.string().test(
      "phoneValidation",
      i18n.t(`phoneRequired`),
      (item) => {
        console.log("item: ", item);
        console.log("isPhoneValid(item): ", isPhoneValid(item));
        return isPhoneValid(item);
      }
    );
    //   .required(i18n.t(`phoneRequired`))

    //   .matches(/^\d{10}$/, i18n.t(`phoneInvalid`));
  }

  if (fields.includes("DateOfBirth")) {
    schemaShape.DateOfBirth = Yup.date()
      .required(i18n.t(`dateOfBirthRequired`))
      .transform((value, originalValue) => {
        // If the date is an empty string, return undefined to allow Yup to handle the empty value
        if (originalValue === "") return undefined;
        const date = new Date(originalValue);
        return isNaN(date) ? undefined : date;
      })
      .max(new Date(), i18n.t(`dateOfBirthFuture`));
  }
  if (fields.includes("OnlyPasswordRequired")) {
    schemaShape.Password = Yup.string().required(i18n.t(`passwordRequired`));
  }
  if (fields.includes("Password")) {
    schemaShape.Password = Yup.string()
      .required(i18n.t(`passwordRequired`))
      .min(8, i18n.t(`passwordLength`))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        i18n.t(`passwordComplexity`)
      );
  }
  if (fields.includes("NewPassword")) {
    schemaShape.NewPassword = Yup.string()
      .required(i18n.t(`newPasswordRequired`))
      .min(8, i18n.t(`passwordLength`))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        i18n.t(`passwordComplexity`)
      );
  }

  if (fields.includes("Confirm_Password")) {
    schemaShape.Confirm_Password = Yup.string()
      .required(i18n.t(`confirmPasswordRequired`))
      .oneOf([Yup.ref("Password")], i18n.t(`confirmPasswordMatch`));
  }
  if (fields.includes("Confirm_Password2")) {
    schemaShape.Confirm_Password2 = Yup.string()
      .required(i18n.t(`confirmPasswordRequired`))
      .oneOf([Yup.ref("NewPassword")], i18n.t(`confirmPasswordMatch`));
  }

  if (fields.includes("Description")) {
    schemaShape.Description = Yup.string().required(
      i18n.t(`descriptionRequired`)
    );
  }

  if (fields.includes("PortfolioLink")) {
    schemaShape.PortfolioLink = Yup.string().required(
      i18n.t(`portfolioLinkRequired`)
    );
  }

  if (fields.includes("Subject")) {
    schemaShape.Subject = Yup.string().required(i18n.t(`subjectRequired`));
  }

  if (fields.includes("Descriptiontext")) {
    schemaShape.Descriptiontext = Yup.string().required(
      i18n.t(`descriptiontextRequired`)
    );
  }

  if (fields.includes("Oldpassword")) {
    schemaShape.Oldpassword = Yup.string().required(i18n.t(`oldPasswordRequired`));
  }

  if (fields.includes("OTPVerification")) {
    schemaShape.OTPVerification = Yup.string().required(i18n.t(`enterOTP`));
    // .matches(/^[0-9]{4}$/, "Please enter a valid 4-digit OTP");
  }

  if (fields.includes("NewPassword")) {
    schemaShape.NewPassword = Yup.string()
      .required(i18n.t(`enterNewPassword`))
      .min(8, i18n.t(`passwordLength`))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/,
        i18n.t(`passwordComplexity`)
      );
  }

  if (fields.includes("Confirm_New_Password")) {
    schemaShape.Confirm_New_Password = Yup.string()
      .required(i18n.t(`confirmPasswordRequired`))
      .oneOf([Yup.ref("NewPassword")], i18n.t(`confirmPasswordMatch`));
  }

  if (fields.includes("AcceptTerms")) {
    schemaShape.AcceptTerms = Yup.boolean().oneOf(
      [true],
      i18n.t(`acceptTermsRequired`)
    );
    // return Yup.object().shape(schemaShape);
  }

  if (fields.includes("name")) {
    schemaShape.name = Yup.string()
      .required("Please enter name")
      .matches(/^[a-zA-Z0-9]+(?: [a-zA-Z0-9]+)*$/, "Please enter a valid name")
      .min(2)
      .max(32);
  }

  if (fields.includes("Username")) {
    schemaShape.Username = Yup.string()
      .required("Please enter username")
      .matches(
        /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<,>,.?/~\\-]{4,22}$/,
        "Please enter a valid username"
      )
      .min(4)
      .max(22);
  }

  if (fields.includes("Lastname")) {
    schemaShape.Lastname = Yup.string()
      .required("Please enter last name")
      .matches(/^[a-zA-Z]+$/, "Please enter a valid last name");
  }

  if (fields.includes("Message")) {
    schemaShape.Description = Yup.string().optional();
  }

  if (fields.includes("age")) {
    schemaShape.age = Yup.boolean().oneOf([true], "Please select the age");
  }

  // event validation

  if (fields.includes("EventTitle")) {
    schemaShape.EventTitle = Yup.string()
      .required("Please enter event title")
      .max(64)
      .matches(
        /^[A-Za-z]+(?:\s+[A-Za-z]+)*$/,
        "Please enter a valid event title"
      );
  }

  if (fields.includes("EventVenue")) {
    schemaShape.EventVenue = Yup.string()
      .required("Please enter event venue")
      .max(200);
  }

  if (fields.includes("EventStartTime")) {
    schemaShape.EventStartTime = Yup.string().required(
      "Please enter event start time"
    );
  }

  if (fields.includes("EventEndTime")) {
    schemaShape.EventEndTime = Yup.string()
      .required("Please enter event end time")
      .when("EventStartTime", (startTime, schema) => {
        console.log("startTime: ", startTime);
        return schema.test({
          test: function (endTime) {
            if (startTime && endTime && endTime <= startTime) {
              return this.createError({
                message: "End time must be greater than start time",
                path: "EventEndTime",
              });
            }
            return true;
          },
        });
      });
  }

  if (fields.includes("EventStartDate")) {
    schemaShape.EventStartDate = Yup.date()
      .required("Please enter event start date")
      .typeError("Please enter a valid date");
  }

  if (fields.includes("EventEndDate")) {
    schemaShape.EventEndDate = Yup.date()
      .required("Please enter event end date")
      .typeError("Please enter a valid date");
  }

  if (fields.includes("EventCategory")) {
    schemaShape.EventCategory = Yup.string().required(
      "Please enter event category"
    );
  }

  if (fields.includes("dropdown")) {
    schemaShape.dropdown = Yup.string().required("Please select an option");
  }

  if (fields.includes("search")) {
    schemaShape.search = Yup.string().required("Please select");
  }

  if (fields.includes("EventImage")) {
    schemaShape.EventImage = Yup.string().required("Please select image");
  }

  if (fields.includes("EventDescription")) {
    schemaShape.EventDescription = Yup.string().required(
      "Please enter event description"
    );
  }

  if (fields.includes("EventBookingLink")) {
    schemaShape.EventBookingLink = Yup.string()
      .required("Please enter event booking link")
      .url("Event booking must be a link")
      .matches(/^(ftp|http|https):\/\/[^ "]+$/, "Invalid booking link");
  }

  if (fields.includes("QuestionTitle")) {
    schemaShape.QuestionTitle = Yup.string()
      .required("Please enter question title")
      .matches(
        /^\S/,
        "Please enter a valid question title without leading spaces"
      );
  }

  if (fields.includes("QuestionDescription")) {
    schemaShape.QuestionDescription = Yup.string()
      .required("Please enter question description")
      .matches(
        /^\S/,
        "Please enter a valid question title without leading spaces"
      );
  }

  return Yup.object().shape(schemaShape);
};

export default ValidationSchema;
