import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useLoaderData, useLocation } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import DefaultTemp from "./components/DefaultTemp";
// import Testimonials from "./components/Testimonials";
import Testimonials from "./components/Testimonials1";
import Subscriptionplan from "./components/Subscriptionplan";
import { useTranslation } from "react-i18next";
import Header2 from "../../components/Header2";
import {
  getToken,
  removeUserDataFromLocal,
} from "../../Common/LocalStorageService";
import { useNavigate } from "react-router-dom";
import useWindowSize from "../../hooks/use-window-size";
import Swal from "sweetalert2";
import { showPromiseNotification } from "../../Common/notification";
import { getTemplate, getTemplateData, logout } from "../../api/apiHandler";
import TemplateDetailsModal from "../../components/TemplateDetailsModal";
import ShareModal from "../../components/ShareModal";

const Index = () => {
  const [isLogin, setIsLogin] = useState(false);
  const [isSignup, setIsSignup] = useState(false);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isTemplateDetailsModalOpen, setIsTemplateDetailsModalOpen] =
    useState(false);
  console.log('isTemplateDetailsModalOpen: ', isTemplateDetailsModalOpen);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [shareId, setShareId] = useState();
  const [templateData, setTemplateData] = useState({});
  console.log('templateData: ', templateData);
  const [isshare, setIsShare] = useState((location?.state && location?.state?.is_share) ? true : false)
  const windowSize = useWindowSize();
  const videoRef = useRef();
  const handleLogOut = useCallback(() => {
    Swal.fire({
      title: t("areYouSure"),
      text: t("logoutText"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      cancelButtonText: t("cancelText"),
      confirmButtonText: t("logOutConfirm"),
    }).then((result) => {
      if (result.isConfirmed) {
        // Perform deletion logic here
        const myPromise = new Promise((resolve, reject) =>
          logout({}).then((response) => {
            console.log("response: ", typeof response, response);
            if (response.code == 200) {
              localStorage.setItem("isLogin", "false");
              removeUserDataFromLocal();
              resolve(response);
              navigate("/");
            } else {
              reject(response);
            }
          })
        );
        showPromiseNotification(myPromise);
      }
    });
  }, [navigate, t]);
  useEffect(() => {
    if (location?.state) {
      if (location?.state == "testimonial") {
        document.getElementById("testimonial").scrollIntoView({
          behavior: "smooth",
          block: windowSize.width >= 992 ? "center" : "start",
        });
      } else if (location?.state == "pricing") {
        document.getElementById("pricing").scrollIntoView({
          behavior: "smooth",
          block: windowSize.width >= 992 ? "center" : "start",
        });
      }

      window.history.replaceState({}, "Title");
    }
  }, [location?.state, windowSize.width]);
  const handleTutorial = () => {
    if (location.pathname !== "/") {
      navigate("/", {
        state: "achievment",
      });
      return;
    }
    document.getElementById("achievment").scrollIntoView({
      behavior: "smooth",
      block: windowSize.width >= 992 ? "center" : "start",
    });
  };
  useEffect(() => {
    if (location?.state?.istutorial) {
      handleTutorial();
    }

  }, [handleTutorial, location?.state?.istutorial])
  useEffect(() => {
    if (location?.state?.is_share && isshare) {
      console.log('location?.state: ', location?.state);
      const id = location?.state?.path.match(/\/copy-template\/(\d+)\//)?.[1];

      getTemplateData({ template_id: id }).then((response) => {
        console.log('response123: ', response);
        if (response?.code == 200) {
          console.log('response1234: ', response);
          setTemplateData(response?.data[0]);
          setIsTemplateDetailsModalOpen(true);
        }
      });
    }
  }, [])
  useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.load(); // Ensure video is loaded
    }
  }, []);
  return (
    <main>
      {getToken() ? (
        <Header2 />
      ) : (
        <Header
          openLogin={isLogin}
          setOpenLogin={setIsLogin}
          openSignup={isSignup}
          setOpenSignup={setIsSignup}
          state={location}
          setIsShare={setIsShare}
        />
      )}
      {/* <Header2 /> */}

      <section className="about_sction" id="about">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-5 col-md-6 col-sm-6 col-12">
              <div className="title-text">
                <h1
                  className="home_banner_title"
                  dangerouslySetInnerHTML={{ __html: t("indextitle") }}
                ></h1>
                <p>{t("subtitle")}</p>
                {getToken() ? (
                  <div className="text-start auth-button">
                    <Link to="/home" className="btn me-2 btn-login mb-2">
                      {t("startDesigning")}
                    </Link>

                    <a onClick={handleLogOut} className="btn btn-outline mb-2">
                      {t("Logout")}
                    </a>
                  </div>
                ) : (
                  <div className="text-start auth-button">
                    <a
                      className="btn me-2 btn-login mb-2"
                      onClick={(e) => {
                        // HandleLoginModal(e);
                        setIsLogin(true);
                      }}
                    >
                      {t("loginButton")}
                    </a>
                    <a
                      className="btn btn-outline mb-2"
                      onClick={(e) => {
                        // HandleSignupModal(e);
                        setIsSignup(true);
                      }}
                    >
                      {t("signupButton")}
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-6 col-12 ">
              <div className="about_mobile_banner">
                {/* <img
                  src={process.env.PUBLIC_URL + "/assets/images/Mega_promo.svg"}
                /> */}
                <video
                  src={process.env.PUBLIC_URL + "/assets/video/Mega_promo.mp4"}
                  // src={process.env.REACT_APP_S3_URL + "user/synrmbn_2024_08_28_17_36_55.mp4"}
                  autoPlay
                  playsInline
                  loop
                  muted
                  preload="none"
                  disablePictureInPicture
                  style={{ objectFit: 'contain', height: '100%', width: '100%' }}
                ></video>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Achivements /> */}
      <section className="achivement_section how_its_works" id="achievment">
        <div className="container">
          <div className="row align-items-center  justify-content-center">
            <div className="col-lg-10 col-md-11 col-sm-12 col-12 mx-auto">
              <div className="white-bg">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <div className="magnify_popup mt-0">
                      <a href="#">
                        <iframe
                          width="100%"
                          height="315"
                          src="https://www.youtube.com/embed/ozzcpSTuvcE?si=2MnxTMt8EiGi9Onc" 
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin"
                          allowFullScreen
                        // style={{ width: '100%', height: '100%', maxWidth: '100%' }}
                        ></iframe>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 col-sm-6 col-12 mx-auto">
                    <div className="title-text">
                      <h1
                        dangerouslySetInnerHTML={{
                          __html: t("howItWorksTitle"),
                        }}
                      ></h1>
                      <p>{t("howItWorksSubtitle")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <DefaultTemp setOpenLogin={setIsLogin} />
      <Testimonials />
      <Subscriptionplan
        //  HandleLoginModal={HandleLoginModal}
        setIsLogin={setIsLogin}
      />
      <Footer isLandingPage />
      {isTemplateDetailsModalOpen && (
        <TemplateDetailsModal
          data={templateData || {}}
          setTemplateData={setTemplateData}
          isTemplateDetailsModalOpen={isTemplateDetailsModalOpen}
          setShareId={setShareId}
          setIsTemplateDetailsModalOpen={setIsTemplateDetailsModalOpen}
          setIsShareModalOpen={setIsShareModalOpen}
          setIsLogin={setIsLogin}
          isshare={isshare}
          setIsShare={setIsShare}
        />
      )}
      {isShareModalOpen && (
        <ShareModal
          isShareModalOpen={isShareModalOpen}
          shareId={shareId}
          setIsShareModalOpen={setIsShareModalOpen}
        />
      )}
    </main>
  );
};

export default Index;
