import AWS from "aws-sdk";
import moment from "moment";

function generateRandomFileName() {
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < 7; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  const currentDateTime = moment().format("YYYY_MM_DD_HH_mm_ss");
  const filename = `${result}_${currentDateTime}`;
  return filename;
}
// Function to upload JSON data to S3
const uploadOrUpadateJSONToS3 = (jsonData, fileName, folderName) => {
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_S3_REGION,
  });

  const s3 = new AWS.S3();

  return new Promise((resolve, reject) => {
    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: `templify/${folderName}/${fileName}`,
      Body: JSON.stringify(jsonData),
      ContentType: "application/json",
      // ACL: "private",
      ACL: "private",
      // ACL: "public",
    };

    // Upload the file to S3
    console.log("params: ", params);
    s3.putObject(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        console.log("S3 data: ", data);
        resolve({ ...data, key: params.Key }); // Return the URL of the uploaded file
      }
    });
  });
};

// Function to fetch JSON data from S3
const fetchJSONFromS3 = (fileName) => {
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_S3_REGION,
  });

  const s3 = new AWS.S3();
  return new Promise((resolve, reject) => {
    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: fileName,
      Expires: 900,
    };

    // Generate a pre-signed URL with a 15-minute expiration
    const url = s3.getSignedUrl("getObject", params);
    console.log(" Signed url: ", url);

    // Fetch the JSON data using the pre-signed URL
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch JSON data from S3");
        }
        return response.json();
      })
      .then((jsonData) => {
        resolve(jsonData);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { uploadOrUpadateJSONToS3, fetchJSONFromS3, generateRandomFileName };

const uploadToS3 = (file, fileName, folderName) => {
  return new Promise((resolve, reject) => {
    // Configure AWS SDK with your credentials
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_S3_REGION,
    });

    const s3 = new AWS.S3();

    // Define the parameters for uploading
    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: `templify/${folderName}/${fileName}`,
      Body: file,
      ContentType: file.type,
      // ACL: "private", // Set the ACL permissions for the file
      // ACL: "public",
      ACL: "private",
      // CacheControl: "no-cache, no-store, must-revalidate", // Prevent caching
    };

    // Upload the file to S3
    s3.putObject(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        console.log("S3 data: Thumbnail", data);
        resolve({ ...data, key: params.Key }); // Return the URL of the uploaded file
      }
    });
  });
};

export default uploadToS3;
