import React, { useCallback, useEffect, useRef, useState } from "react";
import Innerheader from "../../components/Innerheader";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Sidebar from "../../components/Sidebar";
import { deleteTemplate, getMyTemplate } from "../../api/apiHandler";
import TemplateDetailsModal from "../../components/TemplateDetailsModal";
import ShareModal from "../../components/ShareModal";
import { getUserDataFromLocal } from "../../Common/LocalStorageService";
import Swal from "sweetalert2";
import { showPromiseNotification } from "../../Common/notification";

const VideoComponent = ({ item, HandleDeleteTemplate, editTemplate, templateType }) => {
  const videoRef = useRef(null);

  // Event handlers for hover
  const handleMouseEnter = (e) => {
    console.log('handleMouseEnter: ');
    if (videoRef && videoRef.current) {
      // setIsPlaying(index);
      videoRef.current.play(); // Start playing the video
    }
  };

  const handleMouseLeave = (e) => {
    console.log('handleMouseEnter: 55664564');
    if (videoRef && videoRef.current) {
      // setIsPlaying(-1);
      videoRef.current.pause(); // Pause the video
    }
  };
  useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.load(); // Ensure video is loaded
    }
  }, [item?.video_url]);
  return (
    <>
      {item?.is_video == 1 && (
        <a className="video_show2">
          <img
            src={process.env.PUBLIC_URL + "/assets/images/icon/video-pause.svg"}
          />
        </a>
      )}
      <div className="overlay_hover hoverMyproject" onMouseEnter={(e) => { handleMouseEnter(e) }}
        onMouseLeave={(e) => { handleMouseLeave(e) }}>
        <a className="template_image ">
          {/* <div className="template_view_Main" style={{ padding: '0' }}> */}
          {item?.is_video == 1 && item?.video_url ? (
            <video
              src={item?.video_url}
              ref={videoRef}
              loop
              muted
              playsInline
              preload="auto"
              style={{ objectFit: 'contain', height: '100%', width: 'auto' }}
            ></video>
          ) : (
            <img src={item?.thumbnail} alt="" />
          )}
          {/* style={{ objectFit: 'contain' }} */}
          {/* </div> */}
        </a>
        <div className="overlay_div">
          {/* <div className={`d-flex ${item?.is_video == 1 ? "justify-content-between" : "justify-content-end"}`}> */}
          <div className={`d-flex justify-content-end`}>
            {/* {item?.is_video == 1 && (
            <a >
              <img
                src={process.env.PUBLIC_URL + "/assets/images/icon/video-pause.svg"}
              />
            </a>
          )} */}
            <div className="top_li">
              {/* <a
                                        onClick={() => {
                                          setShareId(item?.id);
                                          setIsShareModalOpen(true);
                                        }}
                                      >
                                        <img src="assets/images/share.svg" />
                                      </a> */}
              {templateType != "rejected" && (
                <a
                  onClick={() => {
                    editTemplate(item);
                  }}
                >
                  <img src={`${process.env.PUBLIC_URL}/assets/images/edit.svg`} alt="" />
                </a>
              )}
              {
                (!templateType || templateType != "accepted") && (
                  <a
                    onClick={() => {
                      HandleDeleteTemplate(item.id);
                    }}
                  >
                    <img src={`${process.env.PUBLIC_URL}/assets/images/delete.svg`} alt="" />
                  </a>
                )
              }
            </div>
          </div>
          <div className="bottom_li">
            <div
              className="user_name cursor-pointer"
            // onClick={() => userinfo(item?.user_id)}
            >
              {/* <img src={item?.profile_image} /> */}
              <h6 className="user-name-h6">{item?.name}</h6>
            </div>
            {/* <a
                                          onClick={() => {
                                            setTemplateData(item);
                                            setIsTemplateDetailsModalOpen(true);
                                          }}
                                        >
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/images/info.svg"
                                            }
                                            className="infoImg"
                                          />
                                        </a> */}
          </div>
        </div>
      </div>
    </>
  );
}
const MyProjects = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const user = getUserDataFromLocal();

  const [invitedTemplate, setInvitedTemplate] = useState([]);
  console.log('invitedTemplate: ', invitedTemplate);
  const [templateType, setTemplateType] = useState();
  console.log('templateType: ', templateType);
  const [isTemplateDetailsModalOpen, setIsTemplateDetailsModalOpen] =
    useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [shareId, setShareId] = useState();
  const [templateData, setTemplateData] = useState({});
  const userinfo = useCallback(
    (id) => {
      navigate(`/designer-profile/${id}`);
    },
    [navigate]
  );

  const editTemplate = useCallback(
    (data) => {
      navigate(`/edit-template/${data?.id}`);
    },
    [navigate]
  );
  const HandleDeleteTemplate = useCallback(
    (templateId) => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-outline-success',
          cancelButton: 'btn btn-outline-danger mx-2',
        },
        buttonsStyling: false,
      })
      swalWithBootstrapButtons
        .fire({
          title: i18n.t("areYouSure"),
          text: i18n.t("deleteTemplateMsg"),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: i18n.t("deleteIt"),
          cancelButtonText: i18n.t("noCancel"),
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const myPromise = new Promise(async (resolve, reject) => {
              deleteTemplate({ template_id: templateId }).then((response) => {
                if (response.code == 200) {
                  const filteredData = invitedTemplate.filter((item) => item.id !== templateId)
                  setInvitedTemplate(filteredData)
                  resolve(response);
                } else {
                  reject(response);
                }
              });
            })
            showPromiseNotification(myPromise);
          }
        })
    },
    [i18n, invitedTemplate]
  );

  useEffect(() => {
    getMyTemplate({}).then((response) => {
      if (response.code === 200) {
        setInvitedTemplate(response.data);
      }
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <Innerheader />
      <div className="wrapper">
        <Sidebar />
        {isTemplateDetailsModalOpen && (
          <TemplateDetailsModal
            data={templateData || {}}
            setTemplateData={setTemplateData}
            isTemplateDetailsModalOpen={isTemplateDetailsModalOpen}
            setIsTemplateDetailsModalOpen={setIsTemplateDetailsModalOpen}
          // setIsShareModalOpen={setIsShareModalOpen}
          />
        )}

        {/* {isShareModalOpen && (
          <ShareModal
            shareId={shareId}
            isShareModalOpen={isShareModalOpen}
            setIsShareModalOpen={setIsShareModalOpen}
          />
        )} */}

        <div className="content flex-grow-1">
          <section className="category_main_title">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 mb-4 d-flex justify-content-between align-items-center flex-wrap">
                  <h2 className="section_title">{t("myProjects")}</h2>
                </div>
                {/* <div className="col-12 mb-4 d-flex align-items-center flex-wrap lg-hide" style={{ fontSize: '15px' }}>
                  <p onClick={() => { navigate('/home') }}>{t("home")}</p><p className="ms-1 me-1">{" > "}{" "}</p><p><b> {t("myProjects")}</b></p>
                </div> */}
                {(user && user?.type == "designer") && (
                  <div className="col-12 mb-4">
                    <nav className="MyProjectsNavTabBox"  >
                      <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <button class="nav-link active" id="nav-in-progress-tab" data-bs-toggle="tab" data-bs-target="#nav-in-progress" type="button" role="tab" aria-controls="nav-in-progress" aria-selected="true" onClick={() => { setTemplateType() }}>
                          {t("in_progress")}
                        </button>
                        <button class="nav-link " id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="false" onClick={() => { setTemplateType('accepted') }}>{t("myTemplates")}</button>
                        <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" onClick={() => { setTemplateType('requested') }}>{t("inReview")}</button>
                        <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false" onClick={() => { setTemplateType('rejected') }}>{t("rejected")}</button>
                      </div>
                    </nav>
                  </div>
                )}
                <div className="col-12">
                  <div className="row">
                    {/* <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-4">
                      <div class="ul_list"> */}
                    {invitedTemplate.length ? (
                      invitedTemplate?.map((item) => {
                        if (templateType == item.type) {
                          return (
                            // <li>
                            <div className="col-xl-2 col-lg-3 col-md-6 col-sm-4 col-6 mb-4 position-relative">
                              {/* <div className="overlay_hover hoverMyproject">
                                <a className="template_image ">
                                  {item?.is_video == 1 && item?.video_url ? (
                                    <video
                                      src={item?.video_url}
                                      autoPlay
                                      loop
                                      muted
                                      style={{ objectFit: 'contain', height: '100%', width: 'auto' }}
                                    ></video>
                                  ) : (
                                    <img src={item?.thumbnail} alt="" />
                                  )}
                                </a>
                                <div className="overlay_div">
                                  <div className={`d-flex ${item?.is_video == 1 ? "justify-content-between" : "justify-content-end"}`}>
                                    {item?.is_video == 1 && (
                                      <a >
                                        <img
                                          src={process.env.PUBLIC_URL + "/assets/images/icon/video-pause.svg"}
                                        />
                                      </a>
                                    )}
                                    <div className="top_li">
                                      <a
                                        onClick={() => {
                                          editTemplate(item);
                                        }}
                                      >
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/edit.svg`} alt="" />
                                      </a>
                                      {
                                        !templateType && (
                                          <a
                                            onClick={() => {
                                              HandleDeleteTemplate(item.id);
                                            }}
                                          >
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/delete.svg`} alt="" />
                                          </a>
                                        )
                                      }
                                    </div>
                                  </div>
                                  <div className="bottom_li">
                                    <div
                                      className="user_name cursor-pointer"
                                    >
                                      <h6 className="user-name-h6">{item?.name}</h6>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                              <VideoComponent item={item} HandleDeleteTemplate={HandleDeleteTemplate} editTemplate={editTemplate} templateType={templateType} />
                            </div>
                            // </li> 
                          );
                        }
                      })
                    ) : (
                      <div
                        style={{ height: "100px" }}
                        className="fw-light d-flex justify-content-center align-items-center  w-100"
                      >
                        {t("noDataFound")}
                      </div>
                    )}
                    {/* </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
};

export default MyProjects;
