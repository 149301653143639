import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import checkValidation from "../../../../validation/ValidationSchema";
import { useTranslation } from "react-i18next";
import { forgetPassword } from "../../../../api/apiHandler";
import { showPromiseNotification } from "../../../../Common/notification";

const ForgotPassword = (props) => {
  const {
    forgotemail,
    closeForgotModal,
    openDashboardModal,
    setLoginResponse,
  } = props;
  const schema2 = checkValidation(["Email"]);
  const { t, i18n } = useTranslation();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm({
    resolver: yupResolver(schema2),
  });

  const onSubmit2 = (data) => {
    console.log("data: ", data);
    const payload = {
      email: data?.Email,
    };

    const myPromise = new Promise((resolve, reject) =>
      forgetPassword(payload).then((response) => {
        console.log("response: ", typeof response, response);
        if (response.code == 200) {
          setLoginResponse(payload);
          openDashboardModal();
          reset2();
          resolve(response);
          closeForgotModal();
        } else {
          reject(response);
        }
      })
    );
    showPromiseNotification(myPromise);
    // navigate("/");
  };

  return (
    <>
      <div
        className={`modal fade ${forgotemail ? "show" : ""}`}
        id="otp"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        style={{ display: forgotemail ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <form onSubmit={handleSubmit2(onSubmit2)}>
            <div className="modal-content">
              <div className="modal-body">
                <button
                  type="button"
                  className="custom_btn"
                  onClick={closeForgotModal}
                  aria-label="Close"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/close.svg"}
                  />
                </button>
                <div className="modal_forgot">
                  <h6> {t("forgotPassword")}</h6>
                  <p>{t("resetPassword")}</p>
                  <div className="login_form mt-4">
                    <div className="row">
                      <div className="col-12 mb-3">
                        <div className="form-group left_fixed">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("email")}
                            {...register2("Email")}
                          />
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/email.svg"
                            }
                          />
                        </div>
                        {errors2.Email && (
                          <span className="text-danger">
                            {" "}
                            {errors2.Email.message}
                          </span>
                        )}
                      </div>
                      <div className="col-12 mb-3">
                        <div className="form-group left_fixed">
                          <button type="submit" className="btn btn-theme">
                            {t("submit")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
    </>
  );
};

export default ForgotPassword;
