import React, { useCallback, useEffect, useState } from "react";
import Innerheader from "../../components/Innerheader";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PhoneInput } from "react-international-phone";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import checkValidation from "../../validation/ValidationSchema";
import Sidebar from "../../components/Sidebar";
import { PhoneNumberUtil } from "google-libphonenumber";
import {
    getUserDataFromLocal,
    saveUserDataToLocal,
} from "../../Common/LocalStorageService";
import moment from "moment/moment";
import {
    showErrorMessage,
    showPromiseNotification,
} from "../../Common/notification";
import { cancelSubscription, editProfile, getCurrentSubscription } from "../../api/apiHandler";
import uploadToS3, { generateRandomFileName } from "../../Common/s3AwsService";
import imageCompression from 'browser-image-compression';
import DeleteAccont from "../../components/DeleteAccont";
import ChangeCardDetails from "../Subscription/Components/ChangeCardDetails";
import Swal from "sweetalert2";
import _ from 'lodash'

const MyAccount = () => {
    const navigate = useNavigate();
    const [isImgChange, setIsImgChange] = useState(false);
    const [isLoading, setIsLoading] = useState(0);
    const [profileImage, setProfileImage] = useState({});
    const [subscriptionData, setSubscriptionData] = useState([]);
    console.log("subscriptionData: ", subscriptionData);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const user = getUserDataFromLocal();
    const designerType = getUserDataFromLocal()?.type;

    const schema = checkValidation(["Fullname", "Email", "DateOfBirth"]);
    const handledelete = () => {
        setIsDeleteModalOpen(true);
    };
    const userData = getUserDataFromLocal();

    const {
        register,
        handleSubmit,
        clearErrors,
        setError,
        setValue,
        watch,
        formState: { errors, isSubmitted },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            profile_image: userData?.profile_image,
            Fullname: userData?.full_name,
            Email: userData?.email,
            PhoneNumber: userData?.country_code + userData?.mobile_no,
            DateOfBirth: moment(userData?.dob).format("YYYY-MM-DD"),
        },
    });

    const { t, i18n } = useTranslation();

    const onSubmit = async (data) => {
        console.log("data?.PhoneNumber: ", data?.PhoneNumber);
        const phoneNumber = data?.PhoneNumber
            ? phoneUtil.parseAndKeepRawInput(data?.PhoneNumber)
            : null;

        const payload = {
            full_name: data?.Fullname,
            email: data?.Email,
            dob: data?.DateOfBirth,
            country_code: phoneNumber && `+${phoneNumber.getCountryCode()}`,
            phone: phoneNumber && phoneNumber.getNationalNumber().toString(),
        };
        if (isImgChange) {
            const compressedImage = await imageCompression(profileImage, {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 600,
                useWebWorker: true,
                initialQuality: 0.3
            });
            await uploadToS3(
                compressedImage,
                `${generateRandomFileName()}.${compressedImage.type.split("/")[1]}`,
                "user"
            ).then((response) => {
                payload.profile_image = response.key;
            });
        }
        const myPromise = new Promise((resolve, reject) =>
            editProfile(payload).then((response) => {
                if (response.code == 200) {
                    saveUserDataToLocal(response.data);
                    setIsLoading(isLoading + 1)
                    resolve(response);
                } else {
                    reject(response);
                }
            })
        );
        showPromiseNotification(myPromise);

        // navigate("/home");
    };

    const phoneUtil = PhoneNumberUtil.getInstance();
    const isPhoneValid = (phone) => {
        try {
            return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
        } catch (error) {
            return false;
        }
    };

    const handleFileImageChange = (event) => {
        console.log("event: ", event);
        const selectedFiles = event.target.files;
        if (selectedFiles.length > 0) {
            const file = selectedFiles[0];
            if (file.type.startsWith("image/")) {
                const imageUrl = URL.createObjectURL(file);
                setValue("profile_image", imageUrl);
                setIsImgChange(true);
                setProfileImage(file);
            } else {
                showErrorMessage("Only image is allow");
            }
        }
    };
    const handleCancelPlan = useCallback(() => {
        const myPromise = new Promise((resolve, reject) =>
            cancelSubscription({}).then((response) => {
                if (response.code === 200) {
                    saveUserDataToLocal(response?.data);
                    resolve(response);
                } else {
                    reject(response);
                }
            })
        );
        showPromiseNotification(myPromise);
    }, []);
    const handleCancelPlanAlert = () => {
        Swal.fire({
            title: t("areYouSure"),
            text: t("cancelPlanMsg"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: t("doIt"),
            cancelButtonText: t("cancelButtonText"),
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    handleCancelPlan();
                } catch (error) {
                    console.error('Save operation failed:', error);
                }
            }
        });
    };
    useEffect(() => {
        getCurrentSubscription({}).then((response) => {
            if (response.code == 200) {
                setSubscriptionData(response.data);
            } else {
                setSubscriptionData([]);
            }
        })
    }, [])
    return (
        <main>
            <Innerheader isLoading={isLoading} setIsLoading={setIsLoading} />
            <div className="wrapper">
                <Sidebar />
                <div className="category_main_title myAccountTitle">
                    <div className="container-fluid">
                        <section className="MyAccountSection">
                            <div className="row">
                                <div className="col-12">
                                    <div className="login_header">
                                        <a
                                            className="cursor-pointer"
                                            onClick={() => navigate(-1)}
                                        >
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/images/left_arrow.svg"
                                                }
                                            />{" "}
                                            {t("back")}
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="MyAccountMainBox">
                                            <div className="MyAccountHeadingBox">
                                                <h2 className="MyAccountHeading">
                                                    {t("myAccount")}
                                                </h2>
                                            </div>
                                            <div className="MyAccountTabBox">
                                                <nav>
                                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                        <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">
                                                            {t("editProfile")}
                                                        </button>
                                                        <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">
                                                            {t("subscription")}
                                                        </button>
                                                        {/* <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">
                                                    Payout
                                                </button> */}
                                                        <button className="nav-link" id="nav-General-tab" data-bs-toggle="tab" data-bs-target="#nav-General" type="button" role="tab" aria-controls="nav-General" aria-selected="false">
                                                            {t("general")}
                                                        </button>
                                                    </div>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="MyAccountContentMainBox">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="tab-content" id="nav-tabContent">
                                                <div className="tab-pane fade  show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabIndex={0}>
                                                    <div className="content flex-grow-1">
                                                        <section className="">
                                                            <div className="container-fluid">
                                                                <div className="row">
                                                                    <div className="col-12 mb-4 ">
                                                                        <div className="main_gray">
                                                                            {/* <div className="row">
                                                                        <div className="col-12">
                                                                            <div className="login_header">
                                                                                <a
                                                                                    className="cursor-pointer"
                                                                                    onClick={() => navigate("/home")}
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            process.env.PUBLIC_URL +
                                                                                            "/assets/images/left_arrow.svg"
                                                                                        }
                                                                                    />{" "}
                                                                                    {t("back")}
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                            <div className="row align-items-center justify-content-evenly">
                                                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-3 ">
                                                                                    <div className="edit_profile">
                                                                                        <img src={watch("profile_image")} />
                                                                                        <img
                                                                                            src={
                                                                                                process.env.PUBLIC_URL +
                                                                                                "/assets/images/camera.svg"
                                                                                            }
                                                                                            className="camera_icon"
                                                                                        />
                                                                                        <input type="file" accept="image/png, image/jpeg, image/jpg" onChange={handleFileImageChange} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-3">
                                                                                    <div className="title-text">
                                                                                        {/* <h5 className="mb-2 fw-bold text-center">
                                                                                    {t("editProfile")}
                                                                                </h5> */}

                                                                                        <p className="mx-auto text-center">
                                                                                            {t("editPersonalDetails")}
                                                                                        </p>
                                                                                        <form onSubmit={handleSubmit(onSubmit)}>
                                                                                            <div className="login_form">
                                                                                                <div className="row">
                                                                                                    <div className="col-12 mb-3">
                                                                                                        <div className="form-group left_fixed">
                                                                                                            <input
                                                                                                                type="text"
                                                                                                                className="form-control"
                                                                                                                placeholder={t("fullName")}
                                                                                                                {...register("Fullname")}
                                                                                                            />
                                                                                                            <img
                                                                                                                src={
                                                                                                                    process.env.PUBLIC_URL +
                                                                                                                    "/assets/images/account.svg"
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                        {errors.Fullname && (
                                                                                                            <span className="text-danger">
                                                                                                                {" "}
                                                                                                                {errors.Fullname.message}
                                                                                                            </span>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="col-12 mb-3">
                                                                                                        <div className="form-group left_fixed">
                                                                                                            <input
                                                                                                                type="text"
                                                                                                                className="form-control"
                                                                                                                placeholder={t("email")}
                                                                                                                {...register("Email")}
                                                                                                            />
                                                                                                            <img
                                                                                                                src={
                                                                                                                    process.env.PUBLIC_URL +
                                                                                                                    "/assets/images/email.svg"
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                        {errors.Email && (
                                                                                                            <span className="text-danger">
                                                                                                                {" "}
                                                                                                                {errors.Email.message}
                                                                                                            </span>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="col-12 mb-3">
                                                                                                        <PhoneInput
                                                                                                            defaultCountry="ua"
                                                                                                            value={watch("PhoneNumber")}
                                                                                                            className="form-group"
                                                                                                            inputProps={{
                                                                                                                className: "form-control",
                                                                                                                name: "PhoneNumber",
                                                                                                            }}
                                                                                                            inputStyle={{
                                                                                                                borderTopLeftRadius: "0",
                                                                                                                borderBottomLeftRadius: "0",
                                                                                                                borderLeft: "0",
                                                                                                                paddingLeft: "0",
                                                                                                            }}
                                                                                                            countrySelectorStyleProps={{
                                                                                                                buttonContentWrapperClassName:
                                                                                                                    "form-control h-100",
                                                                                                                buttonContentWrapperStyle: {
                                                                                                                    borderTopRightRadius: "0",
                                                                                                                    borderBottomRightRadius: "0",
                                                                                                                    border: "1px solid #E4EAF7",
                                                                                                                    paddingRight: "5px",
                                                                                                                    borderRight: "0",
                                                                                                                },

                                                                                                                buttonClassName: "border-0 h-100",
                                                                                                            }}
                                                                                                            // dialCodePreviewStyleProps={{className:"form-control"}}
                                                                                                            onChange={(phone) => {
                                                                                                                const errorPhone = isPhoneValid(phone);

                                                                                                                if (!errorPhone) {
                                                                                                                    // Set the error if the phone number is invalid
                                                                                                                    setError("PhoneNumber", {
                                                                                                                        type: "phoneInvalid",
                                                                                                                        message: i18n.t(`phoneInvalid`),
                                                                                                                    });
                                                                                                                } else {
                                                                                                                    // Clear any existing phone number error if the number is valid
                                                                                                                    setValue("PhoneNumber", phone);
                                                                                                                    clearErrors("PhoneNumber");
                                                                                                                }
                                                                                                            }}
                                                                                                        />
                                                                                                        {console.log("errors: ", errors)}
                                                                                                        {isSubmitted && errors.PhoneNumber && (
                                                                                                            <span className="text-danger">
                                                                                                                {" "}
                                                                                                                {errors.PhoneNumber.message}
                                                                                                            </span>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="col-12 mb-3">
                                                                                                        <div className="form-group left_fixed">
                                                                                                            <input
                                                                                                                type={"date"}
                                                                                                                className="form-control"
                                                                                                                placeholder={t("dateOfBirth")}
                                                                                                                {...register("DateOfBirth")}
                                                                                                            />
                                                                                                            <img
                                                                                                                src={
                                                                                                                    process.env.PUBLIC_URL +
                                                                                                                    "/assets/images/cake.svg"
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                        {errors.DateOfBirth && (
                                                                                                            <span className="text-danger">
                                                                                                                {" "}
                                                                                                                {errors.DateOfBirth.message}
                                                                                                            </span>
                                                                                                        )}
                                                                                                    </div>

                                                                                                    <div className="col-12 mt-5">
                                                                                                        <div className="form-group left_fixed">
                                                                                                            <button
                                                                                                                type="submit"
                                                                                                                className="btn btn-theme"
                                                                                                            >
                                                                                                                {t("update")}
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </form>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabIndex={0}>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            {subscriptionData && subscriptionData.length > 0 ? (
                                                                <div className="MyAccountSubscriptionBox">
                                                                    <div className="MySubscriptionCard">
                                                                        <div className="MySubscriptionHeader">
                                                                            <h4 className="MySubscriptionHeading">
                                                                                {t("mySubscription")}
                                                                            </h4>
                                                                        </div>
                                                                        <div className="MySubscriptionBody">
                                                                            <div className="MySubscriptionTable">
                                                                                <table>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th>{t("plan")}</th>
                                                                                            <th>{t("id")}</th>
                                                                                            <th>{t("startDate")}</th>
                                                                                            <th>{t("endDate")}</th>
                                                                                            <th>{t("status")}</th>
                                                                                            <th>{t("billingInfo")} </th>
                                                                                            <th colSpan={2}>{t("action")} </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <div className="ProBox">
                                                                                                    <img src={
                                                                                                        process.env.PUBLIC_URL +
                                                                                                        "/assets/images/icon/premium-quality.svg"
                                                                                                    } alt="CrownIcon" className="CrownIcon" />
                                                                                                    <span style={{ paddingRight: "20px" }}>{_.capitalize(subscriptionData[0].subscribed_type)}</span>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                {subscriptionData[0]?.subscription_id}
                                                                                            </td>
                                                                                            <td>
                                                                                                {moment(subscriptionData[0]?.start_date).format('DD MMM, YYYY')}
                                                                                            </td>
                                                                                            <td>
                                                                                                {moment(subscriptionData[0]?.end_date).format('DD MMM, YYYY')}
                                                                                            </td>
                                                                                            <td>
                                                                                                <p className="MyAccountActiveBox">
                                                                                                    {t("active")}
                                                                                                </p>
                                                                                            </td>
                                                                                            <td>
                                                                                                {user?.is_subscribed == 1 && user?.is_subscription_cancel == 0 ? (
                                                                                                    <a className="MyAccountUpdateBox" onClick={() => { setModalOpen(true) }}>
                                                                                                        <img src={
                                                                                                            process.env.PUBLIC_URL +
                                                                                                            "/assets/images/credit-card.svg"
                                                                                                        } alt="MyAccountUploadIcons " className="MyAccountUploadIcons" />
                                                                                                        {t("update")}
                                                                                                    </a>
                                                                                                ) : (
                                                                                                    <div className="text-center"> - </div>
                                                                                                )
                                                                                                }
                                                                                            </td>
                                                                                            <td>
                                                                                                <a className="CancelRenewalLink">
                                                                                                    {
                                                                                                        user?.is_subscribed == 1 && user?.is_subscription_cancel == 0 ? (
                                                                                                            <p onClick={() => { handleCancelPlanAlert() }}>{t("cancelRenewal")}</p>
                                                                                                        ) : (
                                                                                                            t("alreadyCancelled")
                                                                                                        )
                                                                                                    }
                                                                                                </a>
                                                                                            </td>
                                                                                            <td>
                                                                                                <Link to="/subscription" className="UpgradeLink justify-content-center">
                                                                                                    <img src={
                                                                                                        process.env.PUBLIC_URL +
                                                                                                        "/assets/images/icon/premium-quality.svg"
                                                                                                    } alt="premium-quality" className="premium-quality" />
                                                                                                    {t("update")}
                                                                                                </Link>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="text-center">
                                                                    <p>{t("noDataFound")}</p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab" tabIndex={0}>
                                                    333
                                                </div>
                                                <div className="tab-pane fade" id="nav-General" role="tabpanel" aria-labelledby="nav-General-tab" tabIndex={0}>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="BecomeADesignerMainBox">
                                                                <div className="BecomeADesignerBox">
                                                                    <ul className="BecomeADesignerBoxUl">
                                                                        <li className="BecomeADesignerBoxLi  pt-0">
                                                                            {
                                                                                designerType === "designer" ? (
                                                                                    <div className="btn btn-login">
                                                                                        {t("youAreDesigner")}
                                                                                    </div>
                                                                                ) : (
                                                                                    <Link to="/become-designer" className="btn btn-login">
                                                                                        {t("becomeaDesigner")}
                                                                                    </Link>
                                                                                )
                                                                            }
                                                                        </li>
                                                                        <li className="BecomeADesignerBoxLi">
                                                                            <Link to="/change-password" className="BecomeChangeDeleteLink">
                                                                                <img src={
                                                                                    process.env.PUBLIC_URL +
                                                                                    "/assets/images/lock.svg"
                                                                                } alt="lock" className="BecomedeleteIcon" />
                                                                                {t("changePassword")}
                                                                            </Link>
                                                                        </li>
                                                                        <li className="BecomeADesignerBoxLi">
                                                                            <a className="BecomeChangeDeleteLink" onClick={() => { handledelete(); }}>
                                                                                <img src={
                                                                                    process.env.PUBLIC_URL +
                                                                                    "/assets/images/icon/black-delete.svg"
                                                                                } alt="delete" className="BecomedeleteIcon" />
                                                                                {t("deleteAccount")}
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            {isDeleteModalOpen && (
                <DeleteAccont
                    isDeleteModalOpen={isDeleteModalOpen}
                    setIsDeleteModalOpen={setIsDeleteModalOpen}
                />
            )}
            {isModalOpen && (
                <ChangeCardDetails
                    isModalOpen={isModalOpen}
                    setModalOpen={setModalOpen}
                    selectedPlan={{}}
                    planDuration={subscriptionData[0]?.subscription_duration}
                />
            )}
        </main>
    );
};

export default MyAccount;
